import { t } from "i18next";
import { isNull } from "../../../Helpers/utils";

export default function Policies({ isAgree, setIsAgree, error }) {
    return (
        <label className="flex flex-col gap-2 select-none cursor-pointer">
            <div className="flex items-start gap-2">
                <input
                    type={'checkbox'}
                    className={`w-4 h-4 mt-[1px] p-3 rounded-lg transition-colors duration-300 ${!isNull(error) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80'}`}
                    onClick={() => setIsAgree(!isAgree)}
                    onChange={(e) => setIsAgree(e.target.checked)}
                    checked={isAgree}
                />
                <p className="text-fontColor2 textBody-xs2">
                    {t('auth_section_32')} <a href="https://sharely.in/privacy-policy" target="_blank" rel="noreferrer" className="hover:underline text-primary">{t('auth_section_33')}</a> {t('auth_section_34')} <a href="https://sharely.in/terms-and-conditions" target="_blank" rel="noreferrer" className="hover:underline text-primary">{t('auth_section_35')}</a>
                </p>
            </div>
            {!isNull(error) && <p className="textBody-xs3 text-red-500">{error}</p>}
        </label>
    )
}