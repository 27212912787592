import ChangeLang from "../../Language/ChangeLang";
import AuthButton from "../../Shared/Button/AuthButton";
import AuthInput from "../../Shared/Input/AuthInput";
import AuthPhoneInput from "../../Shared/Input/AuthPhoneInput";
import Policies from "./Policies";


export default function RegisterForm({ handleSubmit, name, setName, phone, setPhone, phoneCountry, setPhoneCountry, isAgree, setIsAgree, isLoading, errors, close, t, phoneIsCurrect, setPhoneIsCurrect, showLoginForm }) {
    return (
        <div>
            <div className="w-full flex items-start justify-between mb-4 pb-4">
                <div className="flex flex-col gap-0.5">
                    <h2 className="textHeader-s">{t('auth_section_12')}</h2>
                    <p className="lg:textBody-s3 textBody-xs3 text-fontColor2">{t('auth_section_18')} <span onClick={showLoginForm} className="hover:underline cursor-pointer">{t('auth_section_19')}</span></p>
                </div>
                <ChangeLang />
            </div>

            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-5">
                <AuthInput
                    type="text"
                    title={t('auth_section_14')}
                    placeholder={"Jack Doe"}
                    value={name}
                    change={(e) => setName(e.target.value)}
                    error={errors.name ?? null}
                />

                <AuthPhoneInput
                    type="number"
                    title={t('auth_section_15')}
                    placeholder={"********"}
                    value={phone}
                    change={(v) => setPhone(v)}
                    phoneCountry={phoneCountry}
                    setPhoneCountry={setPhoneCountry}
                    error={errors.phone ?? null}
                    phoneIsCurrect={phoneIsCurrect}
                    setPhoneIsCurrect={setPhoneIsCurrect}
                />

                <Policies
                    isAgree={isAgree}
                    setIsAgree={setIsAgree}
                    error={errors.agree}
                />

                <div className="w-full flex flex-col mt-6">
                    <AuthButton
                        type="submit"
                        name={t('auth_section_17')}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>

                <button
                    type="button"
                    onClick={close}
                    disabled={isLoading}
                    className="textBody-xs2 text-fontColor2 hover:underline">
                    {t('auth_section_31')}
                </button>
            </form>
        </div>
    )
}