import { useState } from "react";
import { isNull } from "../../../Helpers/utils"



export default function AuthInput({
    type = "text",
    title = "Title",
    placeholder = "...",
    value = "",
    change = () => { },
    error = null,
    readOnly = false
}) {

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const isActive = isFocused || value;


    const className = `rounded-md outline-none bg-white py-3 px-4 border ${!isNull(error) ? 'border-red-500' : 'border-primary/15 group-hover:border-primary focus:border-primary'}`;

    return (
        <label className="relative group flex flex-col gap-1 transition-all duration-300">
            <span className={`absolute ltr:left-3 rtl:right-3 bg-white transition-all duration-200 textBody-s2 px-1 ${isActive ? "-top-3 text-fontColor2 cursor-context-menu" : "top-3.5 text-fontColor2/60 cursor-text" }`}>
                {title}
            </span>
            <input
                type={type}
                className={className}
                placeholder={isActive ? placeholder : ''}
                onChange={change}
                value={value}
                readOnly={readOnly}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {!isNull(error) && <p className="textBody-xs3 text-red-500">{error}</p>}
        </label>
    )
}