import { Link } from "react-router-dom";
import { isNull, placeholderImage, renderImage } from "../../../../Helpers/utils";
import { LoaderIcon, NoResults } from "../../../../icons";

export default function SalonInformations({ loading, salonData }) {

    const { name, images } = salonData ?? {};

    const salonImage = !isNull(images) ? renderImage(images) : placeholderImage;

    return loading ? suspense() :
        isNull(salonData) ? notFound() : (
            <div className="w-full border-b border-borderColor bg-cover bg-center" style={{ backgroundImage: `url("${salonImage}")` }}>
                <div className="w-full h-full lg:pt-180 lg:pb-12 pt-14 pb-8 px-4 backdrop-blur-lg bg-black/50">
                    <div className="w-fit mx-auto text-center">
                        <div
                            className="w-32 h-32 bg-primary/5 bg-cover bg-center rounded-xl mx-auto shadow"
                            style={{ backgroundImage: `url("${salonImage}")` }}
                        >
                        </div>
                        <div className="pt-3">
                            <h3 className="textHeader-xs text-white drop-shadow-md">{name}</h3>
                            {/* <p className="textBody-xs2 text-white drop-shadow-md">5.0 (21)</p> */}
                        </div>
                    </div>
                </div>
            </div>
        )
}

const suspense = () => {
    return (
        <>
            <div className="w-full border-b border-borderColor bg-primary/5 animate-pulse">
                <div className="w-full h-full lg:pt-180 lg:pb-12 pt-14 pb-8 px-4">
                    <div className="w-fit mx-auto text-center">
                        <div className="w-32 h-32 bg-primary/10 bg-cover bg-center rounded-xl mx-auto shadow"></div>
                        <div className="pt-3">
                            <div className="h-6 w-2/4 bg-fontColor2/10 rounded-lg mx-auto"></div>
                        </div>
                    </div>
                </div>
            </div>

            <LoaderIcon className="w-10 h-10 fixed bottom-4 left-2/4 -translate-x-2/4" />
        </>
    )
}

const notFound = () => {
    return (
        <div className="w-full">
            <div className="w-full h-full lg:pt-20 lg:pb-14 pt-16 pb-10 px-4">
                <div className="w-fit mx-auto text-center flex flex-col items-center">

                    <NoResults className="w-12 h-12" />
                    <strong className="textBody-m1 mb-2 mt-4">That page can't be found.</strong>
                    <p className="textBody-s2 text-fontColor2/60">Oops! The page you're looking for doesn't exist or has been moved.</p>

                    <Link to={'/'} className="mt-4">
                        <button
                            className={`outline-none whitespace-nowrap rounded-full textBody-s2 py-2.5 px-7 transition-colors duration-200 select-none bg-primary hover:bg-primary2 text-white `}>
                            Home page
                        </button>
                    </Link>

                </div>
            </div>
        </div>
    )
}