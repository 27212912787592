import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "../../../lib/dayjs";
import 'react-datepicker/dist/react-datepicker.css';


export default function CustomDayPicker({ renderCustomHeader, selected, onChange = () => { }, unavailableDates = [] }) {


    const isDateUnavailable = date => {
        if (unavailableDates.includes(dayjs(date).format("YYYY-MM-DD"))) {
            return true;
        } else {
            return dayjs(dayjs(date).format("YYYY-MM-DD")).isBefore(dayjs().format("YYYY-MM-DD"));
        }
    };

    const hanldeChangeDate = value => {
        if (isDateUnavailable(value[0])) {
            return;
        }
        onChange(dayjs(value[0]).format("YYYY-MM-DD"));
    };

    const renderDayContents = (day, date) => {
        if (isDateUnavailable(date)) {
            return <span className="unavailable">{day}</span>;
        } else {
            return <span className="available">{day}</span>;
        }
    };

    return (
        <DatePicker
            monthsShown={1}
            minDate={new Date()}
            selected={dayjs(selected).toDate()}
            onChange={hanldeChangeDate}
            selectsRange
            inline
            renderCustomHeader={renderCustomHeader}
            renderDayContents={renderDayContents}
        />
    );
}