import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SideBar({ stats, appoType }) {

    const { t } = useTranslation()

    const { all, active, confirmed, completed, canceled } = stats ?? {};

    const asideLinks = [
        {
            label: t('dash_appo_2'),
            path: '/dashboard/appointments',
            param: 'all',
            total: all ?? 0,
        }, {
            label: t('dash_appo_3'),
            path: '/dashboard/appointments?status=active',
            param: 'active',
            total: active ?? 0,
        }, {
            label: t('dash_appo_4'),
            path: '/dashboard/appointments?status=confirmed',
            param: 'confirmed',
            total: confirmed ?? 0,
        }, {
            label: t('dash_appo_5'),
            path: '/dashboard/appointments?status=completed',
            param: 'completed',
            total: completed ?? 0,
        }, {
            label: t('dash_appo_6'),
            path: '/dashboard/appointments?status=canceled',
            param: 'canceled',
            total: canceled ?? 0,
        },
    ];

    const isActive = (param) => {
        return param === appoType;
    }

    return (
        <div className="lg:w-[200px] w-full">
            <div className="bg-white flex lg:flex-col lg:gap-1 overflow-x-auto no-scrollbar select-none max-lg:pb-1.5 rounded-lg p-2 shadow-sm">
                {asideLinks.map((link, index) => {
                    return (
                        <Link to={link?.path} key={index} className="flex flex-col">
                            <div className={`flex items-center justify-between gap-2 lg:py-3 py-1.5 lg:px-4 px-3 lg:rounded-lg rounded-md text-fontColor2 transition-colors duration-200 cursor-pointer
                                ${isActive(link?.param) ? 'bg-secondary/5' : 'bg-transparent hover:bg-secondary/5'}
                                `}>
                                <span className="whitespace-nowrap lg:textBody-s3 textBody-xs2">{link.label}</span>
                                <span className="whitespace-nowrap lg:textBody-s3 textBody-xs2">{link.total}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}