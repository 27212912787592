import { useAlerts } from "../../../context/AlertsContext";
import { useAuth } from "../../../hooks/useAuth";
import { n } from "../../../Helpers/utils";
import ProfileInformations from "../../../components/Pages/Profile/ProfileInformations";
import ContactDetails from "../../../components/Pages/Profile/ContactDetails";
import { useTranslation } from "react-i18next";



const AccountProfile = () => {

    const { t } = useTranslation()

    const { authUser } = useAuth();

    const { addAlert } = useAlerts();

    const setAllAlerts = (text, number) => {
        addAlert(text, n(number) === 1 ? 'success' : 'error')
    }

    return (
        <div className="">
            <div className="w-full max-w-3xl mx-auto xl:p-8 lg:p-6 p-4">
                <div className="w-full max-lg:mt-3">
                    <div className="w-full flex justify-between items-center">
                        {/* Text */}
                        <div className="flex flex-col gap-1">
                            <h2 className="flex items-center textHeader-l">
                                {t("dash_account_1")}
                            </h2>
                            <p className="textBody-s3">{t("dash_account_2")}</p>
                        </div>
                    </div>
                </div>
                {/* Content */}
                <div className="mt-6 flex flex-col gap-8">

                    {/* personal info */}
                    <ProfileInformations
                        authUser={authUser}
                        setNotif={setAllAlerts}
                    />


                    {/* contact details */}
                    <ContactDetails
                        authUser={authUser}
                        setNotif={setAllAlerts}
                    />

                </div>
            </div>
        </div>
    )
}

export default AccountProfile;