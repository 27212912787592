import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Authentication from "../components/Authentication";
import Header from "../components/Pages/Home/Header";
import { isNull } from "../Helpers/utils";
import { useAuth } from "../hooks/useAuth";

export default function Layout() {

    const { authUser } = useAuth();
    const { role } = authUser?.data ?? {};

    const navigate = useNavigate()

    /**
     * redirect none auth users to the home page
     */
    useEffect(() => {
        const redirectAuthUser = () => {
            if (!isNull(role)) {
                navigate(role === 'client' ? '/appointments' : '/dashboard/appointments')
            }
        };

        redirectAuthUser();
    }, [role, navigate])

    return (
        <main>
            <Authentication />
            <Header />
            <Outlet />
        </main>
    )
}