import React, { useEffect, useState } from 'react';

const Alert = ({ alert, onClose }) => {
    const [visible, setVisible] = useState(true);

    // Automatically close the alert after 3 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            setTimeout(() => onClose(alert.id), 3000); // Ensure onClose is a function
        }, 3000);

        return () => clearTimeout(timer); // Clear the timeout on unmount
    }, [alert.id, onClose]);

    if (!visible) return null; // Don't render if not visible

    return (
        <div
            className={`w-full max-w-sm mx-auto p-4 mb-4 rounded-lg shadow-lg text-white flex justify-between items-center animation-01 animation-03
        transition-all duration-500 ease-in-out transform 
        ${visible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'} 
        ${alert.type === 'success' ? 'bg-green-500' : alert.type === 'error' ? 'bg-red-500' : 'bg-blue-500'}
      `}
        >
            <span>{alert.message}</span>
            <button onClick={() => setVisible(false)} className="ltr:ml-4 rtl:mr-4 text-xl font-bold leading-none hover:underline">
                &times;
            </button>
        </div>
    );
};

export default Alert;