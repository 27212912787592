import React, { createContext, useState, useContext, useCallback } from 'react';

// Create the Alerts Context
const AlertsContext = createContext();

// Alerts Provider component
export const AlertsProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    // Function to add a new alert
    const addAlert = useCallback((message, type = 'info') => {
        setAlerts((prev) => [...prev, { id: Date.now(), message, type }]);
    }, []);

    // Function to remove an alert by its ID
    const removeAlert = useCallback((id) => {
        setAlerts((prev) => prev.filter((alert) => alert.id !== id));
    }, []);

    return (
        <AlertsContext.Provider value={{ alerts, addAlert, removeAlert }}>
            {children}
        </AlertsContext.Provider>
    );
};

// Custom hook to use the AlertsContext
export const useAlerts = () => useContext(AlertsContext);
