import { Spinner } from "../../../icons";

export default function AvatarLoader({
    borderRadius = "rounded-full",
    size = "w-11 h-11",
    ...props
}) {
    return (
        <div
            {...props}
            className={`${size} ${borderRadius} bg-[#e7e8ff] text-[#6950f3] flex items-center justify-center capitalize text-sm font-bold cursor-context-menu`}>
            <Spinner className="w-6 h-6 animate-spin" />
        </div>
    )
}