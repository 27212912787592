import { isNull } from "../../../../Helpers/utils";
import { Time } from "../../../../icons";


export default function ChooseTime({
    isLoading,
    availableTimes,
    selectedTime,
    setSelectedTime,
    t
}) {

    return (
        <div className="p-4">
            <div className="flex items-center justify-center gap-2">
                <Time className="lg:w-5 lg:h-5 w-4 h-4" />
                <h4 className="lg:textBody-ml2 textBody-ml2 text-fontColor">{t('booking_2')}</h4>
            </div>

            {isLoading ? <Suspense /> :
                <div className="mt-4 grid grid-cols-4 sm:gap-2 gap-1 select-none textBody-s3 text-center">
                    {isNull(availableTimes) ? 'No results!' :
                        availableTimes?.map((t, index) => {

                            const isSelected = selectedTime === t.time;
                            const isBookable = t.can_book;

                            return (
                                <div key={`_t_${index}`}>
                                    {isBookable ? (
                                        <div
                                            onClick={() => setSelectedTime(t.time)}
                                            className={`w-full rounded-lg cursor-pointer py-2.5 px-4 border transition-all duration-200
                                    ${isSelected ? 'bg-secondary text-white border-white' : 'hover:bg-primary/[0.03] border-borderColor'}
                                    `}>
                                            <span className="textBody-xs3 line-clamp-1">{t.time}</span>
                                        </div>
                                    ) : (
                                        <div className={`w-full rounded-lg cursor-not-allowed py-2.5 px-4 border transition-all duration-200 border-borderColor text-[#ccc]`}>
                                            <span className="textBody-xs3 line-clamp-1 line-through decoration-[#e75242]">{t.time}</span>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                </div>}
        </div>
    )
}


const Suspense = () => {

    const _items = Array(29).fill(null);

    return (
        <div className="mt-4 grid grid-cols-4 sm:gap-2 gap-1 select-none textBody-s3 text-center">
            {_items.map((i, index) => (
                <div key={`_s_${index}`} className={`w-full rounded-lg h-[2.5rem] border transition-all duration-200 border-borderColor bg-borderColor/70 animate-pulse`}></div>
            ))}
        </div>
    )
}