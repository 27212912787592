
export default function Calendar() {
    return (
        <div className="w-full mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="mb-3 pb-3">
                <h2 className="textHeader-s">Calendar</h2>
            </div>
            <div className="">

                {/*  */}

            </div>
        </div>
    )
}