
export default function SelectCategory({ data, setCategory, t }) {
    return (
        <div className="flex flex-col gap-4">
            <div className="">
                <h3 className="textBody-s2 text-fontColor2">{t('ticket_9')} <span className="text-red-500">*</span></h3>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
                {data?.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setCategory(item.slug)}
                        className="bg-white border border-dashed border-borderColor shadow-sm p-4 rounded-md hover:border-primary/20 bg-transparent outline-none transition-colors duration-200 textBody-s3">
                        {item.label}
                    </button>
                ))}
            </div>
        </div>
    )
}