import Cookies from 'js-cookie'
import getEnv from '../Helpers/env'

export default function useCookies () {
    
    const setGlobalCookie = ($n, $v) => {
        Cookies.set($n, $v, {
            expires: new Date(2147483647 * 1000),
            secure: true,
            sameSite: 'strict',
            domain: getEnv('appCookiesUrl'),
            path: '/'
        })
    }

    const setCookie = ($n, $v) => {
        Cookies.set($n, $v, {
            expires: new Date(2147483647 * 1000),
            secure: true,
            sameSite: 'strict',
            path: '/'
        })
    }

    const getCookie = ($n) => {
        return Cookies.get($n) ?? false;
    }

    const removeCookie = ($n) => {
        Cookies.remove($n)
    }

    return {
        setGlobalCookie,
        setCookie,
        getCookie,
        removeCookie
    }
}