import ChangeLang from "../../Language/ChangeLang";
import AuthButton from "../../Shared/Button/AuthButton";
import AuthPhoneInput from "../../Shared/Input/AuthPhoneInput";


export default function LoginForm({ handleSubmit, phone, setPhone, phoneCountry, setPhoneCountry, errors, phoneIsCurrect, setPhoneIsCurrect, isLoading, close, t, showRegisterForm }) {
    return (
        <div>
            <div className="w-full flex items-start justify-between mb-4 pb-4">
                <div className="flex flex-col gap-0.5">
                    <h2 className="textHeader-s">{t('auth_section_1')}</h2>
                    <p className="lg:textBody-s3 textBody-xs3 text-fontColor2">{t('auth_section_5')} <span onClick={showRegisterForm} className="hover:underline cursor-pointer">{t('auth_section_6')}</span></p>
                </div>
                <ChangeLang />
            </div>

            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-5">
                <AuthPhoneInput
                    type="number"
                    title={t('auth_section_2')}
                    placeholder={"********"}
                    value={phone}
                    change={(v) => setPhone(v)}
                    phoneCountry={phoneCountry}
                    setPhoneCountry={setPhoneCountry}
                    error={errors.phone ?? null}
                    phoneIsCurrect={phoneIsCurrect}
                    setPhoneIsCurrect={setPhoneIsCurrect}
                />

                <AuthButton
                    type="submit"
                    name={t("auth_section_4")}
                    disabled={isLoading}
                    isLoading={isLoading}
                />

                <button
                    type="button"
                    onClick={close}
                    className="textBody-xs2 text-fontColor2 hover:underline">
                    {t("auth_section_31")}
                </button>
            </form>
        </div>
    )
}