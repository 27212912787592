import { Menu } from "../../icons";
import AvatarDropDown from "./AvatarDropDown";
import { useAuth } from "../../hooks/useAuth";
import NavLogo from "./NavLogo";
import ChangeLang from "../Language/ChangeLang";


export default function NavBar({ toggleSideBar }) {

    const { authUser, logout, isLoading } = useAuth();

    // handle logout click
    const handleLogout = async () => {
        await logout()
    }

    return (
        <nav className="sticky top-0 w-full lg:min-h-[70px] min-h-[50px] bg-white flex items-center justify-between flex-row border-b border-primaryBorder lg:px-0 px-4 py-0 z-[1001]">
            <div className="flex items-center">
                <div className="flex items-center lg:min-w-[200px]">
                    <div className="p-3 max-lg:hidden">
                        <button
                            onClick={toggleSideBar}
                            className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                            <Menu className="lg:w-6 lg:h-6 w-5 h-5" />
                        </button>
                    </div>

                    <NavLogo />
                </div>

                {/* // */}
            </div>


            <div className="flex items-center gap-2 rtl:lg:pl-4 ltr:lg:pr-4">
                {/* <div className="flex items-center">
                    <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Message className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button>

                    <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Notification className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button>
                </div> */}

                <ChangeLang />

                <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} />
            </div>
        </nav>
    )
}