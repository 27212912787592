import Avatar from "../../Shared/Avatar";
import { Edit1 } from "../../../icons";
import { useEffect, useState } from "react";
import Input from "../../Shared/Input";
import FormModal from "../../Shared/FormModal";
import { getFullName, isNull, renderImage } from "../../../Helpers/utils";
import AvatarLoader from "../../Shared/Avatar/AvatarLoader";
import { UpdateUserBaseData } from "../../../lib/api";
import { useTranslation } from "react-i18next";


export default function ProfileInformations({ authUser, setNotif }) {

    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const toogleModal = () => setIsOpen(!isOpen)

    const { data, isLoading, mutate } = authUser ?? {};

    const renderGenderName = (gndr) => {
        switch (gndr) {
            case 'male':
                return t("dash_account_10")
            case 'female':
                return t("dash_account_11")
            default:
                break;
        }
    }

    return (
        <>
            <div className="rounded-lg bg-white border border-primaryBorder/60 shadow-sm">
                <div className="lg:p-6 p-4 flex items-center justify-between border-b border-primaryBorder/50">
                    <h3 className="textBody-m1">{t('dash_account_3')}</h3>
                    <button
                        disabled={isLoading}
                        onClick={toogleModal}
                        className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15">
                        <Edit1 className="w-4 h-4" />
                    </button>
                </div>
                <div className="lg:p-6 p-4">
                    <div className="flex items-start justify-between gap-2">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                                <h4 className="textBody-s1">{t('dash_account_4')}</h4>
                                {!isLoading
                                    ? <span className="text-fontColor textBody-s2">{getFullName(data)}</span>
                                    : <div className="w-44 h-6 rounded-xl bg-fontColor2/15 animate-pulse"></div>}
                            </div>
                            {!isNull(data?.gender ?? null) &&
                                <div className="flex flex-col gap-1">
                                    <h4 className="textBody-s1">{t('dash_account_5')}</h4>
                                    <span className="text-fontColor2 textBody-s2">{renderGenderName(data?.gender)}</span>
                                </div>}
                        </div>
                        {!isLoading
                            ? <div
                                onClick={toogleModal}
                                className="cursor-pointer">
                                <Avatar
                                    src={renderImage(data?.avatar)}
                                    size={'w-12 h-12'}
                                    alt={getFullName(data)}
                                />
                            </div>
                            : <AvatarLoader size={'w-12 h-12'} />}
                    </div>
                </div>
            </div>

            {isOpen && (
                <UpdateData
                    close={toogleModal}
                    setNotif={setNotif}
                    userInfo={{
                        avatar: data.avatar,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        gender: data?.gender,
                    }}
                    refresh={mutate}
                />
            )}
        </>
    )
}


// Edit modal
const UpdateData = ({ close, setNotif, userInfo, refresh }) => {

    const { t } = useTranslation()

    const [avatar, setAvatar] = useState("")
    const [avatarPreview, setAvatarPreview] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [gender, setGender] = useState("")
    const [errors, setErrors] = useState([])
    const [inProgress, setInProgress] = useState(false)


    useEffect(() => {
        setAvatar("")
        setAvatarPreview(renderImage(userInfo.avatar))
        setFirstname(userInfo.firstname)
        setLastname(userInfo.lastname)
        setGender(userInfo?.gender ?? "")
    }, [userInfo])


    // handle change user avatar
    const handleChangeAvatar = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Base64 string
                setAvatarPreview(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setAvatar('')
            setAvatarPreview(renderImage(userInfo.avatar));
        }
    }


    // send request
    const saveChanges = async () => {

        setErrors({})
        setInProgress(true)

        const data = {
            avatar, // Base64 string
            firstname,
            lastname,
            gender,
        };

        const { is_ok, message, type, errors } = await UpdateUserBaseData(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 1 : 0);
        }

        if (is_ok) {
            refresh();
            close();
        }
    }

    return (
        <FormModal
            close={close}
            title={t('dash_account_6')}
            save={saveChanges}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className=" mb-2 flex">
                <label className="relative w-fit border border-primaryBorder/60 rounded-xl">
                    <Avatar
                        src={avatarPreview}
                        borderRadius="rounded-xl"
                        size={'w-24 h-24'}
                        alt={getFullName(userInfo)}
                    />

                    <div className="absolute bottom-0 ltr:right-0 rtl:left-0 bg-secondary text-white rounded-lg p-1.5 cursor-pointer shadow-md">
                        <input type="file" onChange={handleChangeAvatar} accept="image/png, image/jpg, image/jpeg" hidden />
                        <Edit1 className="w-4 h-4" />
                    </div>
                </label>
            </div>


            <Input
                type={"text"}
                title={t('dash_account_7')}
                placeholder={"..."}
                value={firstname}
                change={(e) => setFirstname(e.target.value)}
                error={errors.firstname ?? ''}
            />
            <Input
                type={"text"}
                title={t('dash_account_8')}
                placeholder={"..."}
                value={lastname}
                change={(e) => setLastname(e.target.value)}
                error={errors.lastname ?? ''}
            />
            <label className="flex flex-col gap-2">
                <span className="textBody-s1">{t('dash_account_9')}</span>
                <select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                    <option value="" disabled></option>
                    <option value="male">{t('dash_account_10')}</option>
                    <option value="female">{t('dash_account_11')}</option>
                </select>
                {!isNull(errors.gender) && <p className="textBody-s2 text-red-600">{errors.gender}</p>}
            </label>
        </FormModal>
    )
}