

export default function SuccessMessage({ t }) {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col items-center justify-center py-8 px-6 bg-green-50 border border-dashed border-green-200 rounded-lg text-center space-y-4">
                <div className="w-fit h-fit p-4 bg-green-200 rounded-full mx-auto shadow-sm animation-03">
                    <div className="w-fit h-fit p-4 bg-green-300 rounded-full">
                        <div className="w-fit h-fit p-4 bg-green-400 rounded-full text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill={"none"}
                                className="w-14 h-14"
                            >
                                <path
                                    d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>


                <h2 className="textBody-ml2 text-green-600">{t('ticket_10')}</h2>
                <p className="text-fontColor2/90 textBody-s3">{t('ticket_11')}</p>
            </div>
        </div>
    )
}