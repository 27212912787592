import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import SideBarMobile from "../components/SideBar/SideBarMobile";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import useCookies from "../hooks/useCookies";
import { sideBarStatus } from "../variables";
import { isNull } from "../Helpers/utils";



export default function DashboardLayout() {

    // ######################### SideBar Toggle ######################### //
    const { getCookie, setCookie } = useCookies();

    const sideBarType = getCookie(sideBarStatus) || 'true';

    const [sideBarSt, setSideBarSt] = useState(() => sideBarType === 'true');

    const toggleSideBar = () => {
        const newStatus = !sideBarSt;
        setSideBarSt(newStatus);
        setCookie(sideBarStatus, String(newStatus));
    };

    useEffect(() => setSideBarSt(sideBarType === 'true'), [sideBarType]);

    const isSideBarOpen = Boolean(sideBarSt);
    // ######################### SideBar Toggle ######################### //


    const { isAuth, authUser } = useAuth()
    const { role } = authUser?.data ?? {};

    const navigate = useNavigate()

    /**
     * redirect none auth users to the home page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (!isAuth) {
                navigate('/')
            } else {
                if (!isNull(role) && role !== "member") {
                    navigate('/appointments')
                }
            }
        };

        checkAuthStatus();
    }, [isAuth, role, navigate])

    return (
        <main className="bg-dashBg flex flex-col min-h-dvh text-primaryTextColor font-body">
            <NavBar toggleSideBar={toggleSideBar} />

            <section className="bg-dashBg h-full w-full max-w-full flex flex-1">
                <SideBar isOpen={isSideBarOpen} />
                <div className={`h-full w-full flex-1 ${isSideBarOpen ? 'lg:ltr:ml-[200px] lg:rtl:mr-[200px]' : 'lg:ltr:ml-[64px] lg:rtl:mr-[64px]'} transition-all duration-200`}>
                    <Outlet />
                </div>
            </section>

            <SideBarMobile />
        </main>
    )
}