import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement, // Register the PointElement
    Title,
    Tooltip,
    Legend,
} from "chart.js";


export default function Charts({ period, statsData }) {
    ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

    const data = {
        labels: period,
        datasets: [
            {
                label: "Appointments", // This will be hidden
                data: statsData,
                backgroundColor: "#2d54de",
            }
        ],
    };

    const options = {
        indexAxis: "x",
        scales: {
            // x: {
            //     ticks: {
            //         display: false, // Hides x-axis labels
            //     },
            // },
            y: {
                beginAtZero: true,
                // ticks: {
                //     display: false, // Hides y-axis labels
                // },
            },
        },
        plugins: {
            legend: {
                display: false, // Hides the legend that displays dataset labels
            },
        },
    };

    return <Line data={data} options={options} />;
}