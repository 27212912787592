import { Link, Outlet } from "react-router-dom";
import AvatarDropDown from "../components/NavBar/AvatarDropDown";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import getEnv from "../Helpers/env";


export default function Legal() {

    const { t } = useTranslation()

    const { authUser, isAuth, logout, isLoading } = useAuth()


    const { role } = authUser?.data ?? {};


    // handle logout click
    const handleLogout = async () => {
        await logout()
    }


    useEffect(() => {
        document.documentElement.lang = 'en';
        document.documentElement.dir = 'ltr';
    }, []);


    const url = getEnv('appFrontendUrl');
    const logoPath = `${url}images/sharely-logo-en.png`;

    return (
        <>
            <header className="w-full border-b">
                <div className="max-w-6xl mx-auto h-[65px] w-full px-4 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <Link to="/">
                            <img src={logoPath} alt="logo" className="lg:h-[25px] h-[20px] w-auto -mb-[5px]" />
                        </Link>
                    </div>

                    {!isAuth && (
                        <div className="flex items-center gap-4">
                            <Link to="/auth/login" className="hover:underline textBody-s2 max-sm:hidden">{t("header_btn_1")}</Link>
                            <Link to="/auth" className="px-6 py-2.5 hover:bg-primary2 textBody-s2 bg-primary text-white rounded-full transition-colors duration-200">{t("header_btn_2")}</Link>
                        </div>
                    )}

                    {isAuth && (
                        <div className="flex items-center gap-2">
                            <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} role={role} />
                        </div>
                    )}
                </div>
            </header>

            <main className="max-w-6xl mx-auto w-full px-4 py-16 min-h-dvh">
                <Outlet />
            </main>

            <section className="max-w-6xl mx-auto w-full px-4 flex max-lg:flex-col-reverse lg:justify-between justify-center items-center gap-4 py-4 mt-10">
                <div className="flex items-center gap-2">
                    <span className="textBody-s3 text-primary/50">Copyright 2024 - Sharely</span>
                </div>

                <div className="flex items-center gap-4">
                    <Link to="/privacy-policy" className="textBody-s3 text-primary/80 hover:text-primary whitespace-nowrap">
                        Privacy Policy
                    </Link>
                    <Link to="/terms-and-conditions" className="textBody-s3 text-primary/80 hover:text-primary whitespace-nowrap">
                        Terms and conditions
                    </Link>
                </div>
            </section>
        </>
    )
}