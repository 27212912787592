import { useTranslation } from "react-i18next"
import { Hide1, Spinner } from "../../../icons"
import ModalLayout from "../../../layouts/ModalLayout"

export default function FormModal({
    close = () => { },
    title = "title",
    save = () => { },
    isDisabled = false,
    isLoading = false,
    buttonTitle = null,
    removeBtn = null,
    children
}) {
    
    const { t } = useTranslation()

    return (
        <ModalLayout
            close={close}
        >
            <div className={`bg-white textBody-s3 w-full lg:rounded-lg gap-1 justify-between flex flex-col overflow-hidden max-lg:h-dvh`}>
                <div className="flex items-center justify-between p-4">
                    <h3 className="textBody-m1">{title}</h3>
                    <button
                        onClick={close}
                        className="rounded-lg p-1 hover:bg-primary/5">
                        <Hide1 className="lg:w-5 lg:h-5 w-4 h-4" />
                    </button>
                </div>

                <div className="bg-white py-6 px-4 flex flex-col gap-3 border-y border-primaryBorder lg:max-h-[60vh] max-lg:h-full overflow-y-auto">
                    {children}
                </div>

                <div className="pt-2 pb-3 px-4 flex items-center gap-2 justify-between">
                    <div className="flex items-center gap-2">
                        <button
                            onClick={save}
                            disabled={isDisabled}
                            className={`bg-primary border border-primary text-white ${isDisabled ? 'opacity-90' : 'hover:bg-primary/80 hover:border-primary/80 active:scale-[.99]'} rounded-lg py-2 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m1`}>
                            {!isLoading ? buttonTitle || t('form_modal_1') : <Spinner className="w-6 h-6 animate-spin" />}
                        </button>
                        <button
                            onClick={close}
                            disabled={isDisabled}
                            className={`bg-transparent border border-primaryBorder text-secondaryTextColor ${isDisabled ? 'opacity-90' : 'hover:bg-secondaryTextColor/5 active:scale-[.99]'} rounded-lg py-2 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m2`}>
                            {t('form_modal_2')}
                        </button>
                    </div>
                    {removeBtn ?? removeBtn}
                </div>
            </div>
        </ModalLayout>
    )
}