import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"


export default function Footer() {

    const { t } = useTranslation()

    return (
        <section className="max-w-6xl mx-auto w-full px-4 flex max-lg:flex-col-reverse lg:justify-between justify-center items-center gap-4 py-4 mt-10">
            <div className="flex items-center gap-2">
                <span className="textBody-s3 text-primary/50">Copyright 2024 - Sharely</span>
            </div>

            <div className="flex items-center gap-4">
                <Link to="/privacy-policy" className="textBody-s3 text-primary/80 hover:text-primary whitespace-nowrap">
                    {t("footer_1")}
                </Link>
                <Link to="/terms-and-conditions" className="textBody-s3 text-primary/80 hover:text-primary whitespace-nowrap">
                    {t("footer_2")}
                </Link>
            </div>
        </section>
    )
}