import { Link } from "react-router-dom";
import { Arrow } from "../../../icons";
import ChangeLang from "../../Language/ChangeLang";


export default function SelectAccountType({ t }) {
    return (
        <div className="">
            <div className="w-full flex items-start justify-between mb-4 pb-4">
                <div className="flex flex-col gap-0.5">
                    <h2 className="textHeader-s">{t('auth_section_12')}</h2>
                    <p className="lg:textBody-s3 textBody-xs3 text-fontColor2">{t('auth_section_13')}</p>
                </div>
                <ChangeLang />
            </div>
            <div className="w-full flex flex-col gap-4">
                <Link to="/?auth=register&t=clients">
                    <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border border-primary/15 hover:bg-primary/[0.03] transition-all duration-200">
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex flex-col gap-1">
                                <span className="textBody-m1">{t("auth_section_8")}</span>
                                <p className="textBody-s3 text-fontColor2">{t("auth_section_9")}</p>
                            </div>
                            <Arrow className="w-5 h-5 rtl:rotate-180" />
                        </div>
                    </div>
                </Link>

                <Link to="/?auth=register&t=barbers">
                    <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border border-primary/15 hover:bg-primary/[0.03] transition-all duration-200">
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex flex-col gap-1">
                                <span className="textBody-m1">{t("auth_section_10")}</span>
                                <p className="textBody-s3 text-fontColor2">{t("auth_section_11")}</p>
                            </div>
                            <Arrow className="w-5 h-5 rtl:rotate-180" />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}