import { useRef, useState } from "react";
import { isNull } from "../../../Helpers/utils"
import { isValidPhoneNumber } from 'libphonenumber-js'
import { Check, UnCheck } from "../../../icons";
import { countriesPhoneCodes } from "../../../db/countries";
import SelectPhoneCountry from "./SelectPhoneCountry";



export default function AuthPhoneInput({
    type = "text",
    title = "Title",
    placeholder = "",
    value = "",
    change = () => { },
    phoneCountry = {},
    setPhoneCountry = () => { },
    error = null,
    readOnly = false,
    phoneIsCurrect = " ",
    setPhoneIsCurrect = () => { },
    isDisabled = false,
}) {

    const inputRef = useRef(null);

    const focusInput = () => {
        const textarea = inputRef.current;
        textarea.focus();
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const isActive = isFocused || !isNull(value) || !isNull(phoneCountry);


    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        if (isDisabled) return;
        setIsOpen(!isOpen)
    }

    const handleChangePhoneNumber = (txt) => {
        setPhoneIsCurrect(isValidPhoneNumber(phoneCountry?.phone_code + txt))
        change(txt)
    }

    const handleChangePhoneCountry = (c) => {
        setPhoneCountry(c)
        change("")
        handleFocus()
        focusInput()
    }

    const countriesList = countriesPhoneCodes ?? [];

    return (
        <div
            onClick={() => {
                if (!isOpen) {
                    handleFocus()
                    if (isNull(phoneCountry)) {
                        // toggle()
                        setPhoneCountry(countriesList[0] ?? {})
                    }
                }
            }}
            className="relative group flex flex-col gap-1 transition-all duration-300">
            <span className={`absolute ltr:left-3 rtl:right-3 bg-white transition-all duration-200 textBody-s2 px-1 ${isActive ? "-top-3 text-fontColor2 cursor-context-menu" : "top-3.5 text-fontColor2/60 cursor-text"}`}>
                {title}
            </span>
            <div className={`rounded-md w-full flex items-center gap-1 cursor-text bg-white px-4 border ${!isNull(error) ? 'border-red-500' : `border-primary/15 group-hover:border-primary ${isActive && 'focus:border-primary'}`}`}>

                {isActive &&
                    <SelectPhoneCountry
                        phoneCountry={phoneCountry}
                        changePhoneCountry={handleChangePhoneCountry}
                        countriesList={countriesList}
                        setPhoneIsCurrect={setPhoneIsCurrect}
                        isDisabled={isDisabled}
                        isOpen={isOpen}
                        toggle={toggle}
                        focusInput={focusInput}
                    />}

                <input
                    ref={inputRef}
                    type={type}
                    className={"w-full outline-none bg-white py-3"}
                    placeholder={isActive ? placeholder : ''}
                    onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    value={value}
                    readOnly={readOnly}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {renderNumberStatus({ value, phoneIsCurrect })}
            </div>
            {!isNull(error) && <p className="textBody-xs3 text-red-500">{error}</p>}
        </div>
    )
}


const renderNumberStatus = ({ value, phoneIsCurrect }) => {
    return isNull(value) || phoneIsCurrect === " " ? null : (
        <>
            {phoneIsCurrect ? (
                <div className="absolute ltr:right-2 rtl:left-2 top-2/4 -translate-y-2/4 border border-green-600 p-0.5 flex shrink-0 justify-between items-center bg-green-600 text-white rounded-3xl cursor-context-menu">
                    <Check className="w-4 h-4" />
                </div>
            ) : (
                <div className="absolute ltr:right-2 rtl:left-2 top-2/4 -translate-y-2/4 border border-red-600 p-0.5 flex shrink-0 justify-between items-center bg-red-600 text-white rounded-3xl cursor-context-menu">
                    <UnCheck className="w-4 h-4" />
                </div>
            )}
        </>
    )
}