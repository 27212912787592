import { isNull } from "../../../Helpers/utils";
import AuthButton from "../../Shared/Button/AuthButton";


export default function PhoneVerification({ verify, cancel, setConfirmationCode, confirmationCode, fullPhoneNumber, isLoading, errors, setErrors, t }) {

    const handleChangeConfirmationCode = (e) => {
        let val = e.target.value;

        setErrors({})

        // Remove any non-numeric characters
        val = val.replace(/\D/g, '');

        // Limit the input to 5 digits
        if (val.length > 5) {
            val = val.slice(0, 5);
        }

        setConfirmationCode(val);
    }


    const canClick = !isNull(confirmationCode) && confirmationCode?.length === 5;


    return (
        <div className="">
            <div className="lg:pb-6 pb-4 flex flex-col gap-1">
                {phoneIcon()}
                <h2 className="textHeader-s">{t("auth_section_26")}</h2>
                <p className="textBody-s3 text-fontColor2/80">{t("auth_section_27")} <span className="textBody-s1">{fullPhoneNumber}</span>.</p>
            </div>
            <div className="lg:py-6 py-4 flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <input
                        type="number"
                        className={`border rounded-lg px-4 py-3 text-center textBody-l1 tracking-[6px] text-fontColor2/60 outline-none focus:shadow ${!isNull(errors.code) ? 'border-red-500' : ''}`}
                        placeholder="00000"
                        value={confirmationCode}
                        onChange={handleChangeConfirmationCode}
                    />
                    {!isNull(errors.code) && <p className="text-red-500 textBody-xs3">{errors.code}</p>}
                </div>

                <div className="">
                    <p className="textBody-s3 text-fontColor2/80">
                        {t("auth_section_28")}
                        <span onClick={() => {
                            setConfirmationCode('')
                            cancel()
                        }} className="textBody-s1 cursor-pointer hover:underline mx-1">{t("auth_section_29")}</span>
                    </p>
                </div>
            </div>
            <div className="lg:pt-6 pt-4 mt-4 border-t w-full flex flex-col gap-2">
                <AuthButton
                    name={t('auth_section_30')}
                    onClick={verify}
                    disabled={isLoading || !canClick}
                    isLoading={isLoading}
                />

                <button
                    onClick={cancel}
                    disabled={isLoading}
                    className="textBody-xs2 text-fontColor2 hover:underline">
                    {t('auth_section_31')}
                </button>
            </div>
        </div>
    )
}

const phoneIcon = () => {
    return (
        <svg
            className="mb-4 w-10 h-10"
            viewBox="0 0 504.4 504.4"
            fill="#000000"
        >
            <g strokeWidth={0} />
            <g strokeLinecap="round" strokeLinejoin="round" />
            <g>
                <path
                    style={{ fill: "#ffdd00" }}
                    d="M294.625,4.4c-9.3,0-17.9,3.4-24.7,10.2l-256,256c-6.8,6.8-10.2,16.2-10.2,24.7h0.8 c0,11.1,8.6,23,8.6,22.2l172.3,172.4c6,6.8,15.4,10.2,23.9,10.2V500c9-0.2,17.3-3.6,23.9-10.2l256-256c6.8-6.8,10.2-16.2,10.2-24.7 s-3.4-17.1-10.2-23.9l-171.5-171.5c0,0-14.5-8.5-23-8.5"
                />
                <path
                    style={{ fill: "#51565F" }}
                    d="M124.025,504.4H4.525c-2.6,0-4.3-1.7-4.3-4.3s1.7-4.3,4.3-4.3h119.5c2.6,0,4.3,1.7,4.3,4.3 S126.525,504.4,124.025,504.4z M209.325,504.4c-10.2,0-19.6-3.4-27.3-11.1l-160.4-160.5c-1.7-1.7-1.7-4.3,0-6s4.3-1.7,6,0 l160.4,160.4c11.9,11.9,30.7,11.9,42.7,0l256-256c11.9-11.9,11.9-30.7,0-42.7l-160.5-160.3c-1.7-1.7-1.7-4.3,0-6s4.3-1.7,6,0 l160.4,160.4c15.4,15.4,15.4,39.3,0,54.6l-256,256C228.925,500.9,219.625,504.4,209.325,504.4z M209.325,453.2 c-2.6,0-4.3-1.7-4.3-4.3V329.4c0-2.6,1.7-4.3,4.3-4.3s4.3,1.7,4.3,4.3v119.5C213.625,451.5,211.925,453.2,209.325,453.2z M72.825,453.2h-68.3c-2.6,0-4.3-1.7-4.3-4.3c0-2.6,1.7-4.3,4.3-4.3h68.3c2.6,0,4.3,1.7,4.3,4.3 C77.125,451.5,75.325,453.2,72.825,453.2z M38.725,402h-34.2c-2.6,0-4.3-1.7-4.3-4.3c0-2.6,1.7-4.3,4.3-4.3h34.1 c2.6,0,4.3,1.7,4.3,4.3C42.925,400.3,41.225,402,38.725,402z M260.525,299.6h-256c-2.6,0-4.3-1.7-4.3-4.3c0,0,0,0,0-0.9 c0-10.2,4.3-19.6,11.1-27.3l256-256c7.7-7.7,17.1-11.1,27.3-11.1l0,0c2.6,0,4.3,1.7,4.3,4.3v256 C298.925,282.5,281.925,299.6,260.525,299.6z M8.825,291h251.7c16.2,0,29.9-13.7,29.9-29.9V9.4c-6.8,0.9-12.8,4.3-17.1,8.5l-256,256 C13.125,278.2,9.625,284.2,8.825,291z M448.325,214.2h-119.5c-2.6,0-4.3-1.7-4.3-4.3s1.7-4.3,4.3-4.3h119.5c2.6,0,4.3,1.7,4.3,4.3 S450.825,214.2,448.325,214.2z"
                />
            </g>
        </svg>
    )
}