import Avatar from "../../../components/Shared/Avatar";
import { getFullName, renderImage } from "../../../Helpers/utils";
import { Date, Time, Whatsapp } from "../../../icons";
import dayjs from "../../../lib/dayjs";
import ActionsDropDown from "./ActionsDropDown";


export default function AppointmentCards({ appo, refresh }) {
    const { id, booking_date, booking_time, booking_status, client, created_at } = appo;

    const appoDate = dayjs(`${dayjs(booking_date).format('YYYY-MM-DD')} ${booking_time}`);

    return (
        <div className="lg:p-4 p-3 flex flex-col bg-white shadow-sm hover:shadow-lg rounded-lg transition duration-200 cursor-context-menu select-none">
            <div className="w-full flex justify-between">
                <a href={`https://wa.me/${client?.phone ?? ""}`} target="_blank" rel="noreferrer" className="group">
                    <div className="flex items-start gap-2">
                        <div className="relative">
                            <Avatar
                                src={client?.avatar ? renderImage(client.avatar) : ''}
                                size="lg:w-11 lg:h-11 w-8 h-8"
                                alt={getFullName(client)}
                                className="rounded-full border-2 border-borderColor shadow-sm"
                            />
                            <Whatsapp className="lg:w-4 lg:h-4 w-3 h-3 group-hover:scale-110 bg-transparent absolute bottom-0 right-0 text-green-400 transition duration-200" />
                        </div>
                        <div>
                            <span className="lg:textBody-s2 textBody-xs2 text-fontColor line-clamp-1">{getFullName(client).charAt(0).toUpperCase() + getFullName(client).slice(1)}</span>
                            <p className="lg:textBody-xs3 textBody-2xs3 text-fontColor2 line-clamp-1">
                                {dayjs(created_at).fromNow()}
                            </p>
                        </div>
                    </div>
                </a>

                <ActionsDropDown
                    id={id}
                    status={booking_status}
                    refresh={refresh}
                />
            </div>

            {/* Date / Time */}
            <div className="mt-3 w-full bg-secondary/5 rounded-lg p-2 flex justify-between gap-1">
                <div className="w-full flex items-center justify-center gap-2">
                    <Date className="w-4 h-4 max-lg:hidden" />
                    <span className="lg:textBody-s3 textBody-xs3 text-fontColor2 hidden lg:block">{dayjs(appoDate).format('ddd, DD MMMM')}</span>
                    <span className="lg:textBody-s3 textBody-xs3 text-fontColor2 lg:hidden block">{dayjs(appoDate).format('ddd, DD MMM')}</span>
                </div>

                <div><div className="bg-primary/10 h-full w-[1px] mx-1"></div></div>

                <div className="w-full flex items-center justify-center gap-2">
                    <Time className="w-4 h-4 max-lg:hidden" />
                    <span className="lg:textBody-s3 textBody-xs3 text-fontColor2 hidden lg:block">{dayjs(appoDate).format('HH:mm A')}</span>
                    <span className="lg:textBody-s3 textBody-xs3 text-fontColor2 lg:hidden block">{dayjs(appoDate).format('HH:mm a')}</span>
                </div>
            </div>
        </div>
    );
}