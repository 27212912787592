import React from 'react';
import Alert from './Alert';
import { useAlerts } from '../../context/AlertsContext';

const AlertsList = () => {
    const { alerts, removeAlert } = useAlerts();

    return (
        <div className="lg:w-full fixed top-5 left-2/4 -translate-x-2/4 space-y-4 z-[12000]">
            {alerts.map((alert) => (
                <Alert key={alert.id} alert={alert} onClose={removeAlert} />
            ))}
        </div>
    );
};

export default AlertsList;
