import Djs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import Cookies from 'js-cookie'
import { languageCookieName } from "../variables";


const currentLang = Cookies.get(languageCookieName) || "en";


const tz = "Africa/Casablanca";


Djs.extend(utc);
Djs.extend(timezone);
Djs.extend(weekday);
Djs.extend(relativeTime);
Djs.extend(duration);

Djs.locale(currentLang);
Djs.tz.setDefault(tz);

const dayjs = (...args) => {
    return Djs(...args).tz(tz);
};

const timezonedUnix = value => {
    return Djs.unix(value).tz(tz);
};

dayjs.unix = timezonedUnix;
dayjs.duration = Djs.duration;


export default dayjs;
