import { Date as DateIcon } from "../../../../icons";
import CustomDayPicker from "../../../../components/Shared/CustomDayPicker";
import DatePickerCustomHeaderTwoMonth from "../../../../components/Pages/Salon/DatePickerCustomHeaderTwoMonth";


export default function ChooseDate({
    selectedDate,
    setSelectedDate,
    t
}) {

    return (
        <div className="p-4 lg:rtl:border-l lg:ltr:border-r lg:border-borderColor/50">
            <div className="flex items-center justify-center gap-2">
                <DateIcon className="lg:w-5 lg:h-5 w-4 h-4" />
                <h4 className="lg:textBody-ml2 textBody-ml2 text-fontColor">{t('booking_1')}</h4>
            </div>

            <div className="relative">
                <div className="">
                    <CustomDayPicker
                        minDate={new Date()}
                        unavailableDates={[]}
                        selected={selectedDate}
                        onChange={date => setSelectedDate(new Date(date))}
                        renderCustomHeader={p => <DatePickerCustomHeaderTwoMonth {...p} />}
                    />
                </div>
            </div>
        </div>
    )
}