import { Link, useLocation } from "react-router-dom";
import { Appointments, Dashboard, Salon, Share } from "../../icons";
import { useAuth } from "../../hooks/useAuth";
import { getFullName, renderImage } from "../../Helpers/utils";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import Avatar from "../Shared/Avatar";
import { useTranslation } from "react-i18next";



export default function SideBarMobile() {

    const { t } = useTranslation()

    const { authUser } = useAuth();

    const { data: user, isLoading } = authUser ?? {};

    // Get path name
    const location = useLocation();
    const { pathname } = location;


    const asideLinks = [
        {
            label: t('mobile_side_bar_1'),
            icon: <Dashboard className="w-5 h-5" />,
            path: '/dashboard',
        }, {
            label: t('mobile_side_bar_2'),
            icon: <Appointments className="w-5 h-5" />,
            path: '/dashboard/appointments',
        }, {
            label: t('mobile_side_bar_3'),
            icon: <Salon className="w-5 h-5" />,
            path: '/dashboard/salon',
        }, {
            label: t('mobile_side_bar_4'),
            icon: <Share className="w-5 h-5" />,
            path: '/dashboard/share',
        },
    ];


    const isActive = (path) => {
        return path === pathname;
    }

    return (
        <>
            <div className="sticky bottom-0 lg:hidden">
                <aside className={`shrink-0 text-white flex items-center md:gap-2 justify-around textBody-s3 bg-primary2 scrollBar1 transition-all duration-300 z-[1001] w-full overflow-y-hidden overflow-x-auto`}>
                    {asideLinks.map((link, index) => {
                        const { path, icon, label } = link ?? {};
                        const act = isActive(path);
                        return (
                            <Link to={`${link.path ?? '/'}`} key={index} className="w-full flex flex-col">
                                <div className={`px-2 py-2.5 flex flex-col items-center gap-1.5 text-white transition-colors duration-200 cursor-pointer border-t-2 ${act ? 'border-secondary bg-white/[0.03]' : 'border-transparent'}`}>
                                    {icon}
                                    <span className={`textBody-2xs3 whitespace-nowrap ${act ? 'text-[#fff]' : 'text-[#eee]'}`}>{label}</span>
                                </div>
                            </Link>
                        )
                    })}

                    <Link to={`/dashboard/account`} className="w-full flex flex-col">
                        <div className={`px-2 py-2.5 flex flex-col items-center gap-1.5 text-white transition-colors duration-200 cursor-pointer border-t-2 ${isActive('/dashboard/account') ? 'border-secondary bg-white/[0.03]' : 'border-transparent'}`}>
                            {isLoading
                                ? <AvatarLoader size={'w-5 h-5'} />
                                : <Avatar
                                    src={renderImage(user?.avatar)}
                                    size={'w-5 h-5'}
                                    alt={getFullName(user)}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            <span className={`textBody-2xs3 ${isActive('/dashboard/account') ? 'text-[#fff]' : 'text-[#eee]'}`}>{t('mobile_side_bar_5')}</span>
                        </div>
                    </Link>
                </aside>
            </div>
        </>
    )
}