import { useSearchParams } from "react-router-dom";
import Content from "./Content";
import SideBar from "./SideBar";
import { GetAllAppointmentsBySalon } from "../../../lib/api";
import { isNull } from "../../../Helpers/utils";
import { useTranslation } from "react-i18next";

export default function Appointments() {

    const { t } = useTranslation()

    const [searchParams] = useSearchParams();
    const statusParam = searchParams.get("status") ?? 'all';


    const params = !isNull(statusParam) && statusParam !== "all" ? { status: statusParam } : {};


    const { data, loading, refresh } = GetAllAppointmentsBySalon(params);
    const { appointments, stats } = data?.data ?? {};


    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 p-4">
            <div className="mb-3 max-lg:mt-3 pb-3">
                <h2 className="textHeader-s">{t("dash_appo_1")}</h2>
            </div>
            <div className="w-full max-w-full flex max-lg:flex-col xl:gap-6 lg:gap-4 gap-4">

                <SideBar
                    stats={stats}
                    appoType={statusParam}
                />

                <Content
                    loading={loading}
                    appointments={appointments?.data}
                    refresh={refresh}
                />

            </div>
        </div>
    )
}