

export default function TermsAndConditions() {
    return (
        <div className="flex flex-col gap-2">
            <h1 className="textHeader-xl mb-6">Terms and Conditions</h1>
            <p>Effective Date: <strong>September 01, 2024</strong></p>

            <p>Welcome to <strong>Sharely</strong>! These Terms and Conditions ("Terms") govern your use of our barber booking platform. By accessing or using Sharely, you agree to these Terms. Please read them carefully before using our service.</p>

            <h2 className="textBody-l1 mt-6">1. Acceptance of Terms</h2>
            <p>By using Sharely, you agree to be bound by these Terms, our Privacy Policy, and any other policies we may publish. If you do not agree, please do not use our platform.</p>

            <h2 className="textBody-l1 mt-6">2. Use of the Platform</h2>
            <p>Sharely allows users to book appointments with barbers. You agree to use the platform only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of the platform complies with all applicable laws.</p>

            <h2 className="textBody-l1 mt-6">3. User Accounts</h2>
            <p>To use certain features of Sharely, you may be required to create an account. You agree to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

            <h2 className="textBody-l1 mt-6">4. Booking and Payments</h2>
            <p>
                <ul className="list-disc ml-8">
                    <li>When booking an appointment through Sharely, you agree to provide accurate booking details.</li>
                    <li>Payments for services are processed through a secure third-party payment provider. By making a payment, you authorize us to charge your selected payment method for the total amount of the booking.</li>
                    <li>Refunds, cancellations, or rescheduling of appointments are subject to the policies of the barber you book with.</li>
                </ul>
            </p>

            <h2 className="textBody-l1 mt-6">5. Barber Responsibilities</h2>
            <p>Barbers using Sharely are responsible for managing their bookings, providing services as described, and complying with applicable laws. Sharely is not responsible for the quality of services provided by barbers.</p>

            <h2 className="textBody-l1 mt-6">6. User Responsibilities</h2>
            <p>You agree not to:</p>
            <ul className="list-disc ml-8">
                <li>Use Sharely for any unlawful or fraudulent purposes.</li>
                <li>Interfere with or disrupt the platform’s operation or security.</li>
                <li>Use automated means (such as bots or scrapers) to access the platform.</li>
                <li>Post or transmit any content that is defamatory, harmful, or violates others' rights.</li>
            </ul>

            <h2 className="textBody-l1 mt-6">7. Intellectual Property</h2>
            <p>All content and materials on Sharely, including trademarks, logos, designs, text, and images, are the property of Sharely or its licensors. You may not use any content from the platform without prior written permission.</p>

            <h2 className="textBody-l1 mt-6">8. Limitation of Liability</h2>
            <p>Sharely provides the platform "as is" and "as available." We make no warranties or representations regarding the accuracy, reliability, or availability of the platform. To the maximum extent permitted by law, Sharely will not be liable for any damages arising from your use of the platform, including direct, indirect, incidental, or consequential damages.</p>

            <h2 className="textBody-l1 mt-6">9. Termination</h2>
            <p>We reserve the right to suspend or terminate your access to Sharely at our discretion if you violate these Terms or engage in any unlawful or harmful activities.</p>

            <h2 className="textBody-l1 mt-6">10. Changes to Terms</h2>
            <p>We may update these Terms from time to time. When we do, we will notify you by updating the "Effective Date" at the top of this page. Your continued use of the platform after changes have been made constitutes your acceptance of the updated Terms.</p>

            <h2 className="textBody-l1 mt-6">11. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of Kingdom of Morocco, without regard to its conflict of law provisions.</p>

            <h2 className="textBody-l1 mt-6">12. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms, please contact us at:</p>
            <p><strong>Email:</strong> <a href="mailto:contact@sharely.in" className="text-primary hover:underline">contact@sharely.in</a>.</p>
            <p><strong>Whatsapp:</strong> <a href="https://wa.me/212723007324" target="_blank" rel="noreferrer" className="text-primary hover:underline">+212723007324</a>.</p>
        </div>
    )
}