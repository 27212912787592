import { isNull } from "../../../Helpers/utils"



export default function Input({
    type="text",
    title="Title",
    placeholder="",
    value="",
    change= () => {},
    error=null,
    isOptional = false,
    readOnly = false
}) {

    const borderStyle = !isNull(error) ? 'border border-red-600 outline-none' : `border border-primaryBorder/60 hover:border-primaryBorder/90 ${readOnly ? 'outline-none cursor-not-allowed' : 'outline-primary/80'}`;

    return (
        <label className="flex flex-col gap-2">
            <span className="textBody-s1">{title} {isOptional && <i className="textBody-xs3">(Optional)</i>}</span>
            <input
                type={type}
                className={`p-3 rounded-lg transition-colors duration-300 ${borderStyle}`}
                placeholder={placeholder}
                onChange={change}
                value={value}
                readOnly={readOnly}
            />
            {!isNull(error) && <p className="textBody-s2 text-red-600">{error}</p>}
        </label>
    )
}