import { useEffect } from 'react';


export default function FixSiteDNS() {
    useEffect(() => {
        const currentURL = window.location.href;
        if (currentURL.includes("www.")) {
            const newURL = currentURL.replace("www.", "");
            window.location.replace(newURL); // Redirect to the new URL
        }
    }, []);

    return null;
}