

export default function PrivacyPolicy() {
    return (
        <div className="flex flex-col gap-2">
            <h1 className="textHeader-xl mb-6">Privacy Policy</h1>
            <p>Effective Date: <strong>September 01, 2024</strong></p>

            <p>Welcome to <strong>Sharely</strong>! This Privacy Policy explains how we collect, use, and protect your personal information when you use our barber booking platform. By using Sharely, you agree to the collection and use of information in accordance with this policy.</p>

            <h2 className="textBody-l1 mt-6">1. Information We Collect</h2>
            <p>We may collect the following types of information when you use our app:</p>
            <ul className="list-disc ml-8">
                <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and other contact information you provide when creating an account or making a booking.</li>
                <li><strong>Payment Information:</strong> We collect payment details when you book a service through our app. Payment information is processed securely through our third-party payment provider.</li>
                <li><strong>Booking Information:</strong> Details about your appointments, including the date, time, and service requested.</li>
                <li><strong>Usage Data:</strong> Information on how you interact with the app, including IP address, device type, and browser information.</li>
            </ul>

            <h2 className="textBody-l1 mt-6">2. How We Use Your Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul className="list-disc ml-8">
                <li>To facilitate your bookings with barbers on our platform.</li>
                <li>To process payments for services.</li>
                <li>To communicate with you regarding your bookings, confirmations, and updates.</li>
                <li>To improve and personalize your experience with Sharely.</li>
                <li>To comply with legal requirements and prevent fraud.</li>
            </ul>

            <h2 className="textBody-l1 mt-6">3. How We Share Your Information</h2>
            <p>We do not sell your personal information. However, we may share your information with:</p>
            <ul className="list-disc ml-8">
                <li><strong>Barbers:</strong> Your name, contact information, and booking details are shared with the barber you book an appointment with.</li>
                <li><strong>Service Providers:</strong> We use third-party service providers to help process payments, manage the platform, and provide other services on our behalf.</li>
                <li><strong>Legal Authorities:</strong> If required by law, we may disclose your information to comply with legal obligations or respond to valid legal requests.</li>
            </ul>

            <h2 className="textBody-l1 mt-6">4. Data Security</h2>
            <p>We take your privacy seriously and implement security measures to protect your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security of your data.</p>

            <h2 className="textBody-l1 mt-6">5. Your Rights</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul className="list-disc ml-8">
                <li>Access: You can request a copy of the information we hold about you.</li>
                <li>Correction: You can request corrections to any inaccurate information.</li>
                <li>Deletion: You can request that we delete your personal data, subject to legal obligations.</li>
            </ul>

            <h2 className="textBody-l1 mt-6">6. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. When we do, we will notify you by updating the "Effective Date" at the top of this page. We encourage you to review this policy regularly to stay informed of how we are protecting your information.</p>

            <h2 className="textBody-l1 mt-6">7. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            <p><strong>Email:</strong> <a href="mailto:contact@sharely.in" className="text-primary hover:underline">contact@sharely.in</a>.</p>
            <p><strong>Whatsapp:</strong> <a href="https://wa.me/212723007324" target="_blank" rel="noreferrer" className="text-primary hover:underline">+212723007324</a>.</p>
        </div>
    )
}