
const NotFound = () => {
    return (
        <div className="p-4">
            <b>404</b>
            <p>Not found!</p>
            <br/>
            <a href="/" className="underline hover:no-underline">
                return back
            </a>
        </div>
    )
}
export default NotFound;