import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from './layouts/DashboardLayout';
import NotFound from './components/NotFound';
import Layout from './layouts/Layout';

import ScrollToTop from './components/ScrollToTop';
import FixSiteDNS from './components/FixSiteDNS';
import { AlertsProvider } from './context/AlertsContext';
import AlertsList from './components/Alerts/AlertsList';
import HomePage from './pages/HomePage';
import Salon from './pages/Salon';
import ClientAppointments from './pages/ClientPages/ClientAppointments';
import ClientAccount from './pages/ClientPages/ClientAccount';

import Dashboard from './pages/DashboardPages/Dashboard';
import Appointments from './pages/DashboardPages/Appointments';
import Calendar from './pages/DashboardPages/Calendar';
import SalonInformations from './pages/DashboardPages/SalonInformations';
import AccountProfile from './pages/DashboardPages/AccountProfile';
import ShareSalon from './pages/DashboardPages/ShareSalon';
import TermsAndConditions from './pages/Legal/TermsAndConditions';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import Legal from './layouts/Legal';
import ClientLayout from './layouts/ClientLayout';
import NewTicket from './pages/DashboardPages/NewTicket';




function App() {
    return (
        <AlertsProvider>
            <AlertsList />
            <BrowserRouter>
                <ScrollToTop />
                <FixSiteDNS />

                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                    </Route>
                    
                    <Route path="/" element={<ClientLayout />}>
                        <Route path='/appointments' element={<ClientAppointments />} />
                        <Route path='/account' element={<ClientAccount />} />
                    </Route>

                    <Route path='/:slug' element={<Salon />} />


                    <Route path="/" element={<Legal />}>
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                    </Route>


                    <Route path="/dashboard" element={<DashboardLayout />}>
                        <Route index element={<Dashboard />} />
                        {/* <Route path='d/x' element={<Dashboard />} /> */}

                        {/* <Route index element={<Appointments />} /> */}
                        <Route path='calendar' element={<Calendar />} />
                        <Route path='appointments' element={<Appointments />} />
                        <Route path='account' element={<AccountProfile />} />
                        <Route path='salon' element={<SalonInformations />} />
                        <Route path='share' element={<ShareSalon />} />

                        <Route path='new-ticket' element={<NewTicket />} />
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </AlertsProvider>
    );
}

export default App;
