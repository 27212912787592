import LanguagesSwitcher from "./LanguagesSwitcher";
import useHtmlAttributes from "../../hooks/useHtmlAttributes";
import { useAuth } from "../../hooks/useAuth";



export default function ChangeLang() {

    const { isAuth } = useAuth()

    const { selectedLang, changeLang } = useHtmlAttributes();

    return (
        <div className="flex items-center gap-4">
            <LanguagesSwitcher
                selectedLang={selectedLang}
                changeLang={changeLang}
                isAuth={isAuth}
            />
        </div>
    )
}