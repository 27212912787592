import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isNull, mergePhoneNumber } from "../../../Helpers/utils";
import { useAuth } from "../../../hooks/useAuth";
import ModalLayout from "../../../layouts/ModalLayout";
import PhoneVerification from "./PhoneVerification";
import RegisterForm from "./RegisterForm";
import SelectAccountType from "./SelectAccountType";


export default function AuthenticationRegister({ close, type, addAlert, showLoginForm }) {

    const { t } = useTranslation()

    const { register, isLoading } = useAuth()

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneCountry, setPhoneCountry] = useState({})
    const [confirmationCode, setConfirmationCode] = useState('')
    const [isAgree, setIsAgree] = useState(true)

    const [phoneIsCurrect, setPhoneIsCurrect] = useState(" ")

    const fullPhoneNumber = mergePhoneNumber(phoneCountry?.phone_code, phone);

    const [errors, setErrors] = useState({})


    const [showEmailConfirmationCode, setShowEmailConfirmationCode] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault()


        setErrors({})


        if (!['barbers', 'clients'].includes(type)) {
            close()
        }


        if (!isAgree) {
            setErrors({ agree: t('auth_section_38') })
            return;
        }


        if (!isNull(confirmationCode)) {
            setShowEmailConfirmationCode(true)
            return;
        }

        if (isNull(name)) {
            addAlert(t('auth_section_37'), 'error')
            return;
        }

        if (!phoneIsCurrect) {
            addAlert(t('auth_section_36'), 'error')
            return;
        }

        register({
            showVerificationBox: setShowEmailConfirmationCode,
            setErrors,
            type: type?.slice(0, -1),
            name,
            phone: fullPhoneNumber,
            code: confirmationCode,
        })
    }

    const handlePhoneVerification = async () => {

        if (isNull(confirmationCode)) return;

        register({
            showVerificationBox: setShowEmailConfirmationCode,
            setErrors,
            type: type?.slice(0, -1),
            name,
            phone: fullPhoneNumber,
            code: confirmationCode,
        })
    }


    return (
        <ModalLayout
            wallBackground={'bg-black/80'}
            className="fixed lg:max-w-[500px] max-w-full w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[1011]"
            close={close}
        >
            <div className="bg-white lg:rounded-lg lg:shadow-lg p-6 max-lg:py-8 max-sm:px-4 max-lg:h-dvh max-lg:overflow-y-auto">
                {isNull(type) ? (
                    <SelectAccountType
                        t={t}
                    />
                ) : showEmailConfirmationCode ? (
                    <PhoneVerification
                        verify={handlePhoneVerification}
                        cancel={() => setShowEmailConfirmationCode(false)}
                        setConfirmationCode={setConfirmationCode}
                        confirmationCode={confirmationCode}
                        fullPhoneNumber={fullPhoneNumber}
                        isLoading={isLoading}
                        errors={errors}
                        setErrors={setErrors}
                        t={t}
                    />
                ) : (
                    <RegisterForm
                        handleSubmit={handleSubmit}
                        name={name}
                        setName={setName}
                        phone={phone}
                        setPhone={setPhone}
                        phoneCountry={phoneCountry}
                        setPhoneCountry={setPhoneCountry}
                        isAgree={isAgree}
                        setIsAgree={setIsAgree}
                        isLoading={isLoading}
                        errors={errors}
                        close={close}
                        t={t}
                        phoneIsCurrect={phoneIsCurrect}
                        setPhoneIsCurrect={setPhoneIsCurrect}
                        showLoginForm={showLoginForm}
                    />
                )}
            </div>
        </ModalLayout>
    );
}