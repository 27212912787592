import { Link } from "react-router-dom";
import { Check } from "../../../../icons";


export default function ReservationSuccessMesasage({ t }) {
    return (
        <div className="w-fit mx-auto mb-20 py-10">
            <div className="flex flex-col items-center justify-center gap-4 p-8 text-center">
                <div className="w-fit h-fit p-4 bg-green-200 rounded-full mx-auto shadow-sm animation-03">
                    <div className="w-fit h-fit p-4 bg-green-300 rounded-full">
                        <div className="w-fit h-fit p-4 bg-green-400 rounded-full text-white">
                            <Check className="w-20 h-20" />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center gap-2 text-center animation-01 w-full">
                    <p className="textBody-l1">{t("booking_4")}</p>
                    <p className="textBody-s3">{t("booking_5")}</p>

                    <div className="w-full flex flex-col gap-1 mx-auto mt-2">
                        <Link to={"/appointments"}>
                            <button className="w-full rounded-full bg-primary hover:bg-primary2 border border-primary px-6 py-3 text-white textBody-s2 transition-colors duration-200">
                                {t("booking_6")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}