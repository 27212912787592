import { useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import { useTranslation } from "react-i18next";


export default function ShareBtn({ link = "", handleCopyLink }) {

    const { t } = useTranslation()

    const [showDropDown, setShowDropDown] = useState(false)
    const toggleDropDown = () => setShowDropDown(!showDropDown)


    const sharingOptions = [
        {
            name: "Twitter",
            icon: '/social-media-icons/twitter.svg',
            onClick: () => { window?.open('https://twitter.com/intent/tweet?url=' + link, '_blank'); }
        }, {
            name: "Whatsapp",
            icon: '/social-media-icons/whatsapp.svg',
            onClick: () => { window?.open('https://api.whatsapp.com/send?text=' + link, '_blank'); }
        }, {
            name: "Facebook",
            icon: '/social-media-icons/facebook.svg',
            onClick: () => { window?.open('https://www.facebook.com/sharer/sharer.php?u=' + link, '_blank'); }
        }, {
            name: "Linkedin",
            icon: '/social-media-icons/linkedin.svg',
            onClick: () => { window?.open('http://www.linkedin.com/shareArticle?mini=true&url=' + link, '_blank'); }
        }, {
            name: "Gmail",
            icon: '/social-media-icons/gmail.svg',
            onClick: () => { window?.open('mailto:?body=' + link) }
        }, {
            name: "Copy link",
            icon: '/social-media-icons/copy-link.svg',
            onClick: handleCopyLink
        }
    ];

    return (
        <div className="relative flex max-lg:flex-col">
            <button
                onClick={toggleDropDown}
                className="outline-none w-full py-3 px-3 border border-secondary rounded-lg text-white bg-secondary hover:bg-secondary/90 textBody-s2">
                {t("dash_share_3")}
            </button>

            {showDropDown && (
                <ModalLayout
                    wallBackground={`bg-black/50`}
                    className={`absolute bottom-[46px] max-lg:left-0 lg:right-0 z-[11014]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-full h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <h3 className="px-4 line-clamp-1 textBody-s1 border-b border-secondaryTextColor/20 pt-4 pb-4 mb-2">
                            {t("dash_share_3")}
                        </h3>
                        <div className="px-4 py-2 grid grid-cols-3 gap-2">
                            {sharingOptions?.map((opt, index) => (
                                <button
                                    onClick={opt?.onClick}
                                    key={index}
                                    className="group flex flex-col items-center justify-center gap-1 whitespace-nowrap py-2 px-4 rounded-md border hover:bg-primary/5 transition-colors duration-300">
                                    <div className="w-12 h-12 flex items-center justify-center rounded-full bg-transparent group-hover:bg-primary/[0.03]">
                                        <img src={opt?.icon} alt="icon" className="w-8 h-8" />
                                    </div>
                                    <span className="textBody-xs3">{opt?.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}