import { isNull } from "../../../Helpers/utils"
import { Link } from "react-router-dom";
import AvatarLoader from "./AvatarLoader";


export default function Avatar({
    src = "",
    borderRadius = "rounded-full",
    size = "w-11 h-11",
    alt = "avatar",
    href = "",
    isLoading = false,
    bgColor = "bg-secondary/10",
    ...props
}) {

    const Pic = () => {
        return (
            <div
                {...props}
                className={`${size} ${borderRadius} ${bgColor} text-[#6950f3] flex items-center justify-center capitalize text-sm font-bold`}>
                {alt.slice(0, 1)}
            </div>
        )
    }

    const Picture = () => {
        return (
            <div
                {...props}
                className={`${size} ${borderRadius} ${bgColor} overflow-hidden`}>
                <img src={src} alt={alt} className="w-full h-full" />
            </div>
        )
    }


    if (isLoading) {
        return <AvatarLoader borderRadius={borderRadius} size={size} />;
    }
    
    else if (isNull(href)) {
        return !isNull(src) ? <Picture /> : <Pic />;
    }
    
    else {
        return <Link to={href}>{!isNull(src) ? <Picture /> : <Pic />}</Link>;
    }
}