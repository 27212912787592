import { useState } from "react";
import { Settings, Spinner } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout"
import { useAlerts } from "../../../context/AlertsContext"
import { isNull } from "../../../Helpers/utils";
import { ChangeAppointmentStatus } from "../../../lib/api";
import { useTranslation } from "react-i18next";


export default function ActionsDropDown({ id, status, refresh }) {

    const { t } = useTranslation()

    const { addAlert } = useAlerts()

    const [showDropDown, setShowDropDown] = useState(false)
    const toggleShowDropDown = () => setShowDropDown(!showDropDown)


    const [inProgress, setInProgress] = useState('')

    const handleChangeStatus = async (s) => {

        if (s === status) {
            toggleShowDropDown();
            return;
        }

        setInProgress(s)

        const data = {
            status: s,
            appointment: id,
        };

        const { is_ok, message, type } = await ChangeAppointmentStatus(data);

        setInProgress('')

        if (type === "validation") {
            //
        } else {
            addAlert(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh();
            toggleShowDropDown();
        }
    }

    return (
        <div className="relative">
            <div className="flex items-center gap-2">
                <button
                    onClick={toggleShowDropDown}
                    className={`lg:px-3.5 px-2.5 py-2 rounded-md lg:textBody-xs2 textBody-2xs2 transition ${renderStatusBadge(status)}`}>
                    {t(renderAppoStatusName(status))}
                </button>
                <button
                    onClick={toggleShowDropDown}
                    className={`p-2 rounded-md textBody-xs2 transition bg-primary/5 hover:bg-primary/10 text-primary`}>
                    <Settings className="w-3.5 h-3.5" />
                </button>
            </div>

            {showDropDown && (
                <ModalLayout
                    wallBackground={`bg-black/15`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1019]`}
                    close={toggleShowDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[160px] h-full rounded-lg overflow-hidden py-2 px-2 flex flex-col textBody-s3">
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('active')}
                            className={`text-start whitespace-nowrap py-2 px-2 rounded-md flex items-center justify-between ${renderAppoStatusColors('active')}`}>
                            <span className="textBody-s3">{t("dash_appo_8")}</span>
                            {!isNull(inProgress) && inProgress === "active" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('confirmed')}
                            className={`text-start whitespace-nowrap py-2 px-2 rounded-md flex items-center justify-between ${renderAppoStatusColors('confirmed')}`}>
                            <span className="textBody-s3">{t("dash_appo_9")}</span>
                            {!isNull(inProgress) && inProgress === "confirmed" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('canceled')}
                            className={`text-start whitespace-nowrap py-2 px-2 rounded-md flex items-center justify-between ${renderAppoStatusColors('canceled')}`}>
                            <span className="textBody-s3">{t("dash_appo_11")}</span>
                            {!isNull(inProgress) && inProgress === "canceled" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                        <button
                            disabled={!isNull(inProgress)}
                            onClick={() => handleChangeStatus('completed')}
                            className={`text-start whitespace-nowrap py-2 px-2 rounded-md flex items-center justify-between ${renderAppoStatusColors('completed')}`}>
                            <span className="textBody-s3">{t("dash_appo_10")}</span>
                            {!isNull(inProgress) && inProgress === "completed" && <Spinner className="w-4 h-4 animate-spin" />}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}

const renderStatusBadge = (status) => {
    switch (status) {
        case 'active':
            return "bg-green-100 text-green-500";
        case 'confirmed':
            return "bg-blue-100 text-blue-500";
        case 'canceled':
            return "bg-red-100 text-red-500";
        case 'completed':
            return "bg-gray-100 text-gray-500";
        default:
            return "bg-gray-100 text-gray-500";
    }
};

const renderAppoStatusColors = (stts) => {
    switch (stts) {
        case 'active':
            return "text-fontColor2/80 hover:bg-green-100 hover:text-green-500";
        case 'confirmed':
            return "text-fontColor2/80 hover:bg-blue-100 hover:text-blue-500";
        case 'canceled':
            return "text-fontColor2/80 hover:bg-red-100 hover:text-red-500";
        case 'completed':
            return "text-fontColor2/80 hover:bg-gray-100 hover:text-gray-500";
        default:
            return "text-fontColor2/80 hover:bg-gray-100 hover:text-gray-500";
    }
}

const renderAppoStatusName = (stts) => {
    switch (stts) {
        case 'active':
            return "dash_appo_8";
        case 'confirmed':
            return "dash_appo_9";
        case 'canceled':
            return "dash_appo_11";
        case 'completed':
            return "dash_appo_10";
        default:
            return "";
    }
}