export const countriesPhoneCodes = [
    { id: 1, en_name: "Morocco", ar_name: "المغرب", fr_name: "Maroc", country_code: "ma", phone_code: "+212", country_flag: "/flags/morocco.jpeg" },
    { id: 2, en_name: "Egypt", ar_name: "مصر", fr_name: "Égypte", country_code: "eg", phone_code: "+20", country_flag: "/flags/eg.png" },
    { id: 3, en_name: "Tunisia", ar_name: "تونس", fr_name: "Tunisie", country_code: "tn", phone_code: "+216", country_flag: "/flags/tn.png" },
    { id: 4, en_name: "Italy", ar_name: "إيطاليا", fr_name: "Italie", country_code: "it", phone_code: "+39", country_flag: "/flags/it.png" },
    { id: 5, en_name: "Ivory Coast", ar_name: "ساحل العاج", fr_name: "Côte d'Ivoire", country_code: "ci", phone_code: "+225", country_flag: "/flags/ci.png" },
    { id: 6, en_name: "Libya", ar_name: "‏ليبيا", fr_name: "Libye", country_code: "ly", phone_code: "+218", country_flag: "/flags/ly.png" },
    { id: 7, en_name: "France", ar_name: "فرنسا", fr_name: "France", country_code: "fr", phone_code: "+33", country_flag: "/flags/fr.png" },
    { id: 8, en_name: "South Africa", ar_name: "جنوب أفريقيا", fr_name: "Afrique du Sud", country_code: "za", phone_code: "+27", country_flag: "/flags/za.png" },
    { id: 21, en_name: "Algeria", ar_name: "الجزائر", fr_name: "Algérie", country_code: "dz", phone_code: "+213", country_flag: "/flags/dz.png" },
    { id: 9, en_name: "Uganda", ar_name: "أوغندا", fr_name: "Ouganda", country_code: "ug", phone_code: "+256", country_flag: "/flags/ug.png" },
    { id: 10, en_name: "Yemen", ar_name: "اليمن", fr_name: "Yémen", country_code: "ye", phone_code: "+967", country_flag: "/flags/ye.png" },
    { id: 11, en_name: "China", ar_name: "الصين", fr_name: "Chine", country_code: "cn", phone_code: "+86", country_flag: "/flags/cn.png" },
    { id: 13, en_name: "Senegal", ar_name: "السنغال", fr_name: "Sénégal", country_code: "sn", phone_code: "+221", country_flag: "/flags/sn.png" },
    { id: 14, en_name: "Palestine", ar_name: "فلسطين", fr_name: "Palestine", country_code: "ps", phone_code: "+970", country_flag: "/flags/ps.png" },
    { id: 15, en_name: "United Kingdom", ar_name: "المملكة المتحدة", fr_name: "Royaume-Uni", country_code: "gb", phone_code: "+44", country_flag: "/flags/gb.png" },
    { id: 16, en_name: "Turkey", ar_name: "تركيا", fr_name: "Turquie", country_code: "tr", phone_code: "+90", country_flag: "/flags/tr.png" },
    { id: 17, en_name: "Singapore", ar_name: "سنغافورة", fr_name: "Singapour", country_code: "sg", phone_code: "+65", country_flag: "/flags/sg.png" },
    { id: 18, en_name: "Niger", ar_name: "النيجر", fr_name: "Niger", country_code: "ne", phone_code: "+227", country_flag: "/flags/ne.png" },
    { id: 19, en_name: "Zambia", ar_name: "زامبيا", fr_name: "Zambie", country_code: "zm", phone_code: "+260", country_flag: "/flags/zm.png" },
    { id: 20, en_name: "Guinea-Bissau", ar_name: "غينيا بيساو", fr_name: "Guinée-Bissau", country_code: "gw", phone_code: "+245", country_flag: "/flags/gw.png" },
    { id: 22, en_name: "Croatia", ar_name: "كرواتيا", fr_name: "Croatie", country_code: "hr", phone_code: "+385", country_flag: "/flags/hr.png" },
    { id: 23, en_name: "Colombia", ar_name: "كولومبيا", fr_name: "Colombie", country_code: "co", phone_code: "+57", country_flag: "/flags/co.png" },
    { id: 24, en_name: "Netherlands", ar_name: "هولندا", fr_name: "Pays-Bas", country_code: "nl", phone_code: "+31", country_flag: "/flags/nl.png" },
    { id: 25, en_name: "Sudan", ar_name: "السودان", fr_name: "Soudan", country_code: "sd", phone_code: "+249", country_flag: "/flags/sd.png" },
    { id: 26, en_name: "Lebanon", ar_name: "لبنان", fr_name: "Liban", country_code: "lb", phone_code: "+961", country_flag: "/flags/lb.png" },
    { id: 27, en_name: "Botswana", ar_name: "بوتسوانا", fr_name: "Botswana", country_code: "bw", phone_code: "+267", country_flag: "/flags/bw.png" },
    { id: 28, en_name: "Qatar", ar_name: "قطر", fr_name: "Qatar", country_code: "qa", phone_code: "+974", country_flag: "/flags/qa.png" },
    { id: 29, en_name: "Paraguay", ar_name: "باراغواي", fr_name: "Paraguay", country_code: "py", phone_code: "+595", country_flag: "/flags/py.png" },
    { id: 30, en_name: "Syria", ar_name: "سوريا", fr_name: "Syrie", country_code: "sy", phone_code: "+963", country_flag: "/flags/sy.png" },
    { id: 31, en_name: "India", ar_name: "الهند", fr_name: "Inde", country_code: "in", phone_code: "+91", country_flag: "/flags/in.png" },
    { id: 32, en_name: "Namibia", ar_name: "ناميبيا", fr_name: "Namibie", country_code: "na", phone_code: "+264", country_flag: "/flags/na.png" },
    { id: 33, en_name: "Bulgaria", ar_name: "بلغاريا", fr_name: "Bulgarie", country_code: "bg", phone_code: "+359", country_flag: "/flags/bg.png" },
    { id: 34, en_name: "Sweden", ar_name: "السويد", fr_name: "Suède", country_code: "se", phone_code: "+46", country_flag: "/flags/se.png" },
    { id: 35, en_name: "Iraq", ar_name: "العراق", fr_name: "Irak", country_code: "iq", phone_code: "+964", country_flag: "/flags/iq.png" },
    { id: 36, en_name: "Germany", ar_name: "ألمانيا", fr_name: "Allemagne", country_code: "de", phone_code: "+49", country_flag: "/flags/de.png" },
    { id: 37, en_name: "Cuba", ar_name: "كوبا", fr_name: "Cuba", country_code: "cu", phone_code: "+53", country_flag: "/flags/cu.png" },
    { id: 38, en_name: "Russia", ar_name: "روسيا", fr_name: "Russie", country_code: "ru", phone_code: "+73", country_flag: "/flags/ru.png" },
    { id: 39, en_name: "Malaysia", ar_name: "ماليزيا", fr_name: "Malaisie", country_code: "my", phone_code: "+60", country_flag: "/flags/my.png" },
    { id: 40, en_name: "Spain", ar_name: "إسبانيا", fr_name: "Espagne", country_code: "es", phone_code: "+34", country_flag: "/flags/es.png" },
    { id: 41, en_name: "Ethiopia", ar_name: "إثيوبيا", fr_name: "Éthiopie", country_code: "et", phone_code: "+251", country_flag: "/flags/et.png" },
    { id: 42, en_name: "Saudi Arabia", ar_name: "السعودية", fr_name: "Arabie Saoudite", country_code: "sa", phone_code: "+966", country_flag: "/flags/sa.png" },
    { id: 43, en_name: "Canada", ar_name: "كندا", fr_name: "Canada", country_code: "ca", phone_code: "+1", country_flag: "/flags/ca.png" },
    { id: 44, en_name: "Malawi", ar_name: "مالاوي", fr_name: "Malawi", country_code: "mw", phone_code: "+265", country_flag: "/flags/mw.png" },
    { id: 45, en_name: "Oman", ar_name: "عمان", fr_name: "Oman", country_code: "om", phone_code: "+968", country_flag: "/flags/om.png" },
    { id: 46, en_name: "Argentina", ar_name: "الأرجنتين", fr_name: "Argentine", country_code: "ar", phone_code: "+54", country_flag: "/flags/ar.png" },
    { id: 47, en_name: "Luxembourg", ar_name: "لوكسمبورغ", fr_name: "Luxembourg", country_code: "lu", phone_code: "+352", country_flag: "/flags/lu.png" },
    { id: 48, en_name: "Iceland", ar_name: "آيسلندا", fr_name: "Islande", country_code: "is", phone_code: "+354", country_flag: "/flags/is.png" },
    { id: 49, en_name: "Australia", ar_name: "أستراليا", fr_name: "Australie", country_code: "au", phone_code: "+61", country_flag: "/flags/au.png" },
    { id: 50, en_name: "Costa Rica", ar_name: "كوستاريكا", fr_name: "Costa Rica", country_code: "cr", phone_code: "+506", country_flag: "/flags/cr.png" },
    { id: 51, en_name: "Philippines", ar_name: "الفلبين", fr_name: "Philippines", country_code: "ph", phone_code: "+63", country_flag: "/flags/ph.png" },
    { id: 52, en_name: "Gibraltar", ar_name: "جبل طارق", fr_name: "Gibraltar", country_code: "gi", phone_code: "+350", country_flag: "/flags/gi.png" },
    { id: 53, en_name: "Denmark", ar_name: "الدنمارك", fr_name: "Danemark", country_code: "dk", phone_code: "+45", country_flag: "/flags/dk.png" },
    { id: 54, en_name: "Cameroon", ar_name: "الكاميرون", fr_name: "Cameroun", country_code: "cm", phone_code: "+237", country_flag: "/flags/cm.png" },
    { id: 55, en_name: "Guinea", ar_name: "غينيا", fr_name: "Guinée", country_code: "gn", phone_code: "+224", country_flag: "/flags/gn.png" },
    { id: 56, en_name: "Bahrain", ar_name: "‏البحرين", fr_name: "Bahreïn", country_code: "bh", phone_code: "+973", country_flag: "/flags/bh.png" },
    { id: 57, en_name: "Czechia", ar_name: "التشيك", fr_name: "Tchéquie", country_code: "cz", phone_code: "+420", country_flag: "/flags/cz.png" },
    { id: 58, en_name: "Somalia", ar_name: "الصومال", fr_name: "Somalie", country_code: "so", phone_code: "+252", country_flag: "/flags/so.png" },
    { id: 59, en_name: "DR Congo", ar_name: "الكونغو", fr_name: "Congo (Rép. dém.)", country_code: "cd", phone_code: "+243", country_flag: "/flags/cd.png" },
    { id: 60, en_name: "Togo", ar_name: "توغو", fr_name: "Togo", country_code: "tg", phone_code: "+228", country_flag: "/flags/tg.png" },
    { id: 61, en_name: "Kenya", ar_name: "كينيا", fr_name: "Kenya", country_code: "ke", phone_code: "+254", country_flag: "/flags/ke.png" },
    { id: 62, en_name: "Vietnam", ar_name: "فيتنام", fr_name: "Viêt Nam", country_code: "vn", phone_code: "+84", country_flag: "/flags/vn.png" },
    { id: 63, en_name: "Uruguay", ar_name: "الأوروغواي", fr_name: "Uruguay", country_code: "uy", phone_code: "+598", country_flag: "/flags/uy.png" },
    { id: 64, en_name: "Mozambique", ar_name: "موزمبيق", fr_name: "Mozambique", country_code: "mz", phone_code: "+258", country_flag: "/flags/mz.png" },
    { id: 65, en_name: "Panama", ar_name: "بنما", fr_name: "Panama", country_code: "pa", phone_code: "+507", country_flag: "/flags/pa.png" },
    { id: 66, en_name: "Tanzania", ar_name: "تنزانيا", fr_name: "Tanzanie", country_code: "tz", phone_code: "+255", country_flag: "/flags/tz.png" },
    { id: 67, en_name: "Burkina Faso", ar_name: "بوركينا فاسو", fr_name: "Burkina Faso", country_code: "bf", phone_code: "+226", country_flag: "/flags/bf.png" },
    { id: 68, en_name: "Norway", ar_name: "النرويج", fr_name: "Norvège", country_code: "no", phone_code: "+47", country_flag: "/flags/no.png" },
    { id: 69, en_name: "South Korea", ar_name: "كوريا الجنوبية", fr_name: "Corée du Sud", country_code: "kr", phone_code: "+82", country_flag: "/flags/kr.png" },
    { id: 70, en_name: "Jordan", ar_name: "الأردن", fr_name: "Jordanie", country_code: "jo", phone_code: "+962", country_flag: "/flags/jo.png" },
    { id: 71, en_name: "Mauritania", ar_name: "موريتانيا", fr_name: "Mauritanie", country_code: "mr", phone_code: "+222", country_flag: "/flags/mr.png" },
    { id: 72, en_name: "Mali", ar_name: "مالي", fr_name: "Mali", country_code: "ml", phone_code: "+223", country_flag: "/flags/ml.png" },
    { id: 73, en_name: "Angola", ar_name: "جمهورية أنغولا", fr_name: "Angola", country_code: "ao", phone_code: "+244", country_flag: "/flags/ao.png" },
    { id: 74, en_name: "Japan", ar_name: "اليابان", fr_name: "Japon", country_code: "jp", phone_code: "+81", country_flag: "/flags/jp.png" },
    { id: 75, en_name: "Chile", ar_name: "تشيلي", fr_name: "Chili", country_code: "cl", phone_code: "+56", country_flag: "/flags/cl.png" },
    { id: 76, en_name: "United States", ar_name: "الولايات المتحدة", fr_name: "États-Unis", country_code: "us", phone_code: "+1", country_flag: "/flags/us.png" },
    { id: 77, en_name: "Ecuador", ar_name: "الإكوادور", fr_name: "Équateur", country_code: "ec", phone_code: "+593", country_flag: "/flags/ec.png" },
    { id: 78, en_name: "Guatemala", ar_name: "غواتيمالا", fr_name: "Guatemala", country_code: "gt", phone_code: "+502", country_flag: "/flags/gt.png" },
    { id: 79, en_name: "Malta", ar_name: "مالطا", fr_name: "Malte", country_code: "mt", phone_code: "+356", country_flag: "/flags/mt.png" },
    { id: 80, en_name: "Gambia", ar_name: "غامبيا", fr_name: "Gambie", country_code: "gm", phone_code: "+220", country_flag: "/flags/gm.png" },
    { id: 81, en_name: "Nigeria", ar_name: "نيجيريا", fr_name: "Nigéria", country_code: "ng", phone_code: "+234", country_flag: "/flags/ng.png" },
    { id: 82, en_name: "Albania", ar_name: "ألبانيا", fr_name: "Albanie", country_code: "al", phone_code: "+355", country_flag: "/flags/al.png" },
    { id: 83, en_name: "Iran", ar_name: "إيران", fr_name: "Iran", country_code: "ir", phone_code: "+98", country_flag: "/flags/ir.png" },
    { id: 84, en_name: "South Sudan", ar_name: "جنوب السودان", fr_name: "Soudan du Sud", country_code: "ss", phone_code: "+211", country_flag: "/flags/ss.png" },
    { id: 85, en_name: "Maldives", ar_name: "المالديف", fr_name: "Maldives", country_code: "mv", phone_code: "+960", country_flag: "/flags/mv.png" },
    { id: 86, en_name: "Brazil", ar_name: "البرازيل", fr_name: "Brésil", country_code: "br", phone_code: "+55", country_flag: "/flags/br.png" },
    { id: 87, en_name: "Serbia", ar_name: "صيربيا", fr_name: "Serbie", country_code: "rs", phone_code: "+381", country_flag: "/flags/rs.png" },
    { id: 88, en_name: "Poland", ar_name: "بولندا", fr_name: "Pologne", country_code: "pl", phone_code: "+48", country_flag: "/flags/pl.png" },
    { id: 89, en_name: "Jamaica", ar_name: "جامايكا", fr_name: "Jamaïque", country_code: "jm", phone_code: "+1876", country_flag: "/flags/jm.png" },
    { id: 90, en_name: "Liberia", ar_name: "ليبيريا", fr_name: "Liberia", country_code: "lr", phone_code: "+231", country_flag: "/flags/lr.png" },
    { id: 91, en_name: "Ghana", ar_name: "غانا", fr_name: "Ghana", country_code: "gh", phone_code: "+233", country_flag: "/flags/gh.png" },
    { id: 92, en_name: "Zimbabwe", ar_name: "زيمبابوي", fr_name: "Zimbabwe", country_code: "zw", phone_code: "+263", country_flag: "/flags/zw.png" },
    { id: 93, en_name: "Belgium", ar_name: "بلجيكا", fr_name: "Belgique", country_code: "be", phone_code: "+32", country_flag: "/flags/be.png" },
    { id: 94, en_name: "Portugal", ar_name: "البرتغال", fr_name: "Portugal", country_code: "pt", phone_code: "+351", country_flag: "/flags/pt.png" },
    { id: 95, en_name: "New Zealand", ar_name: "نيوزيلندا", fr_name: "Nouvelle-Zélande", country_code: "nz", phone_code: "+64", country_flag: "/flags/nz.png" },


    { id: 107, en_name: "Kuwait", ar_name: "الكويت", fr_name: "Koweït", country_code: "kw", phone_code: "+965", country_flag: "/flags/kw.png" },
    { id: 96, en_name: "Mexico", ar_name: "المكسيك", fr_name: "Mexique", country_code: "mx", phone_code: "+52", country_flag: "/flags/mx.png" },
    { id: 97, en_name: "Thailand", ar_name: "تايلاند", fr_name: "Thaïlande", country_code: "th", phone_code: "+66", country_flag: "/flags/th.png" },
    { id: 98, en_name: "Greece", ar_name: "اليونان", fr_name: "Grèce", country_code: "gr", phone_code: "+30", country_flag: "/flags/gr.png" },
    { id: 99, en_name: "Switzerland", ar_name: "سويسرا", fr_name: "Suisse", country_code: "ch", phone_code: "+41", country_flag: "/flags/ch.png" },
    { id: 101, en_name: "Austria", ar_name: "النمسا", fr_name: "Autriche", country_code: "at", phone_code: "+43", country_flag: "/flags/at.png" },
    { id: 102, en_name: "Finland", ar_name: "فنلندا", fr_name: "Finlande", country_code: "fi", phone_code: "+358", country_flag: "/flags/fi.png" },
    { id: 103, en_name: "Ireland", ar_name: "إيرلندا", fr_name: "Irlande", country_code: "ie", phone_code: "+353", country_flag: "/flags/ie.png" },
    { id: 104, en_name: "Peru", ar_name: "بيرو", fr_name: "Pérou", country_code: "pe", phone_code: "+51", country_flag: "/flags/pe.png" },
    { id: 105, en_name: "Pakistan", ar_name: "باكستان", fr_name: "Pakistan", country_code: "pk", phone_code: "+92", country_flag: "/flags/pk.png" },
    { id: 106, en_name: "Indonesia", ar_name: "إندونيسيا", fr_name: "Indonésie", country_code: "id", phone_code: "+62", country_flag: "/flags/id.png" },
];