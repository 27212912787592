import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Welcome() {

    const { t } = useTranslation()

    return (
        <section
            className="w-full bg-cover bg-center"
            style={{ backgroundImage: 'url("/welcome.jpg")' }}
        >
            <div className="bg-gradient-to-t from-black/90 to-black/60 xl:py-52 lg:py-40 md:py-32 py-20">
                <div className="container mx-auto text-center text-white px-2">
                    <h1 className="md:textHeader-2xl textHeader-xl mb-6 flex flex-col text-center">
                        {t("welcome_section_1")}
                    </h1>
                    <p className="textBody-m2 mb-12">
                        {t("welcome_section_2")}
                    </p>
                    <Link
                        to="/?auth=register&t=barbers"
                        className="textBody-m2 bg-white text-primary py-4 sm:px-12 px-6 rounded-full sm:hover:px-20 transition-all duration-200"
                    >
                        {t("welcome_section_3")}
                    </Link>
                </div>
            </div>
        </section>
    )
}