import { useState } from "react";
import { isNull, renderImage } from "../../../Helpers/utils";
import { ArrowBottom1 } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout";
import i18next from "i18next";
import { useTranslation } from "react-i18next";


export default function SelectPhoneCountry({ phoneCountry, changePhoneCountry, countriesList, setPhoneIsCurrect, isDisabled, isOpen, toggle, focusInput }) {

    const { t } = useTranslation()

    // Get data of selected country by "phoneCountry"
    const getSelectedCountryData = !isNull(phoneCountry) ? countriesList?.find(c => c.country_code === phoneCountry?.country_code) : {};

    // render city name
    const renderCountryName = (country = {}) => {
        const currentLang = i18next?.language || "en";

        if (currentLang === "ar") {
            return country?.ar_name;
        } else if (currentLang === "en") {
            return country?.en_name;
        } else if (currentLang === "fr") {
            return country?.fr_name;
        } else {
            return "#";
        }
    }



    // State to store countries filter value
    const [filterSearchValue, setFilterSearchValue] = useState("")
    const [filteredCountries, setFilteredCountries] = useState(countriesList)
    const handleFilterCountries = (val = "") => {

        setFilterSearchValue(val);

        if (!val) {
            setFilteredCountries(countriesList);
        } else {
            const filtered = countriesList.filter((country) => {
                return (
                    country.en_name.toLowerCase().includes(val) ||
                    country.fr_name.toLowerCase().includes(val) ||
                    country.ar_name.includes(val) || // for Arabic names
                    country.country_code.toLowerCase().includes(val) ||
                    country.phone_code.includes(val)
                );
            });

            setFilteredCountries(filtered);
        }
    };

    return (
        <div className="">
            {!isNull(phoneCountry) &&
                <button
                    type="button"
                    disabled={isDisabled}
                    onClick={toggle}
                    className="outline-none flex items-center gap-1 cursor-pointer py-3 bg-white">
                    <div className="rounded border border-borderColor/60 w-[35px] min-h-[20px] bg-primary/5 overflow-hidden">
                        {!isNull(getSelectedCountryData) &&
                            <img
                                className="w-full h-auto"
                                src={renderImage(getSelectedCountryData?.country_flag)}
                                alt="flag"
                            />}
                    </div>
                    <ArrowBottom1 className="w-5 h-5 text-fontColor2" />
                    <div className="bg-white transition-all duration-200 textBody-s3 text-fontColor2/80">{phoneCountry?.phone_code}</div>
                </button>
            }

            {isOpen && (
                <ModalLayout
                    wallBackground="bg-black/10"
                    className="fixed lg:max-w-[500px] w-full -translate-x-2/4 lg:-translate-y-2/4 left-2/4 lg:top-2/4 max-lg:top-0 z-[1020]"
                    close={toggle}
                    ForgetScrollBar={true}
                >
                    <div className={`bg-white textBody-s3 w-full lg:rounded-lg rounded-0 gap-1 justify-between flex flex-col`}>
                        <div className="flex items-center justify-between px-4 h-[55px] cursor-auto">
                            <h3 className="textBody-m1">{t('phone_input_1')}</h3>

                            <input
                                type="text"
                                className="outline-none border rounded-md px-3 py-1.5 textBody-xs3 border-primary/15 hover:border-primary focus:border-primary"
                                placeholder={t('phone_input_2')}
                                value={filterSearchValue}
                                onChange={(e) => handleFilterCountries(e.target.value?.trim()?.toLowerCase())}
                            />
                        </div>

                        <div className="flex flex-col border-t lg:h-[50dvh] max-lg:h-[calc(100dvh_-_55px)] overflow-y-auto pb-4">
                            {!isNull(filteredCountries) &&
                                filteredCountries?.map((c) => {
                                    const isSelected = c?.id === getSelectedCountryData?.id;
                                    return (
                                        <div key={c?.id} className="w-full">
                                            <button
                                                type="button"
                                                // disabled={isSelected}
                                                onClick={() => {
                                                    if (!isSelected) {
                                                        changePhoneCountry(c)
                                                        setPhoneIsCurrect(" ")
                                                        handleFilterCountries("")
                                                    }
                                                    focusInput()
                                                    toggle()
                                                }}
                                                className={`px-4 lg:py-3 py-2 w-full outline-none flex items-center gap-2 transition-colors duration-100 ease-in line-clamp-1 ${isSelected ? 'bg-secondary/5' : 'hover:bg-secondary/5'}`}>
                                                <div className="rounded border border-borderColor/60 w-[35px] min-h-[20px] bg-primary/5 overflow-hidden">
                                                    <img
                                                        className="w-full h-auto"
                                                        src={renderImage(c?.country_flag)}
                                                        alt="flag"
                                                    />
                                                </div>
                                                <span className="first-letter:capitalize textBody-s3">{`${renderCountryName(c)} (${c?.phone_code})`}</span>
                                            </button>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}
