import useSWR from "swr";
import axiosClient from "../lib/axiosClient";


export const csrf = () => axiosClient.get("/sanctum/csrf-cookie");


export const fetchData = async (url, method = 'GET', params = {}, data = {}, preventEmptyParams = false) => {
    try {
        if (preventEmptyParams && (!params || Object.keys(params).length === 0)) return;

        await csrf();
        const response = await axiosClient({
            url,
            method,
            params: params ?? {},
            data: data ?? {}
        });
        return response.data;
    } catch (error) {
        console.error(`Error with ${method} request to ${url}`, error);
        return error?.response?.data ?? {};
    }
};


export const fetcher = async ([url, method, params, data, preventEmptyParams]) => {
    if (preventEmptyParams && (!params || Object.keys(params).length === 0)) return;
    return fetchData(url, method, params, data, preventEmptyParams);
};


export const useSWRFetch = (endpoint, method, params, data, preventEmptyParams = false) => {
    const { data: responseData, error, isLoading, mutate } = useSWR([endpoint, method, params, data, preventEmptyParams], fetcher);
    return {
        data: responseData,
        loading: isLoading,
        refresh: mutate,
        error,
    };
};