import { useSearchParams } from "react-router-dom";
import { useAlerts } from "../../context/AlertsContext";
import AuthenticationLogin from "./Login";
import AuthenticationRegister from "./Register";


export default function Authentication() {

    const { addAlert } = useAlerts()

    // ------------------- Auth Modal Control ------------------- //

    const [params, setParams] = useSearchParams();

    const AUTH = "auth";
    const TYPE = "t";

    const closeModal = () => {
        params.delete(AUTH);
        params.delete(TYPE);
        setParams(params);
    };


    // show login modal
    const showLoginForm = () => {
        params.set(AUTH, 'login');
        params.delete(TYPE);
        setParams(params);
    }

    const showRegisterForm = () => {
        params.set(AUTH, 'register');
        setParams(params);
    }


    const authValue = params.get(AUTH) || '';
    const accountType = params.get(TYPE) || '';

    const isLogin = authValue === "login";
    const isRegister = authValue === "register";

    // ------------------- End Auth Modal Control ------------------- //

    return (
        <>
            {isLogin && <AuthenticationLogin close={closeModal} addAlert={addAlert} showRegisterForm={showRegisterForm} />}
            {isRegister && <AuthenticationRegister close={closeModal} type={accountType} addAlert={addAlert} showLoginForm={showLoginForm} />}
        </>
    );
}