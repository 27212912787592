import Content from "./Content";
import SideBar from "./SideBar";
import { GetAllAppointmentsByClient } from "../../../lib/api";
import { isNull } from "../../../Helpers/utils";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function ClientAppointments() {

    const { t } = useTranslation()

    const [searchParams] = useSearchParams();
    const userType = searchParams.get("status") ?? 'all';

    const params = !isNull(userType) && userType !== "all" ? { status: userType } : {};


    const { data, loading } = GetAllAppointmentsByClient(params);
    const { appointments, stats } = data?.data ?? {};


    return (
        <div className="max-w-6xl mx-auto w-full px-4 flex flex-col lg:py-20 py-10">
            <div className="mb-3 pb-3">
                <h2 className="textHeader-s">{t('client_appo_1')}</h2>
            </div>
            <div className="w-full max-w-full flex max-lg:flex-col xl:gap-6 lg:gap-4 gap-4">

                <SideBar
                    stats={stats}
                    userType={userType}
                />

                <Content
                    loading={loading}
                    appointments={appointments?.data}
                />

            </div>
        </div>
    )
}